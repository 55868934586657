import { Redirect, Route } from "react-router-dom"
import useAuth from "../../auth/hooks/useAuth"
import useQuery from "../hooks/useQuery"

export default function PrivateRoute({ children, ...rest }: any) {
	const query = useQuery()
	const { currentUser } = useAuth()

	return (
		<Route
			{...rest}
			render={({ location }) =>
				currentUser ? (
					query.get("to") ? (
						<Redirect
							to={{
								// TODO
								// @ts-ignore
								pathname: query.get("to"),
								state: { from: location }
							}}
						/>
					) : (
						<>
							{children}
						</>
					)
				) : (
					<Redirect
						to={{
							pathname: location.pathname ? `/signin/?to=${encodeURIComponent(location.pathname)}` : "/signin",
							state: { from: location }
						}}
					/>
				)
			}
		/>
	)
}
