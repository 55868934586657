function Speaker(props: any) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.297 9.178l3.968-4.299C11.883 4.21 13 4.647 13 5.558v13.169c0 .87-1.034 1.325-1.676.737l-4.037-3.701a1 1 0 00-.676-.263H4a1 1 0 01-1-1v-4a1 1 0 011-1h2.562a1 1 0 00.735-.322z"
        fill="#fff"
      />
      <path
        d="M19 7s2 2 2 5-2 5-2 5M16 9s1 1.2 1 3-1 3-1 3"
        stroke="#fff"
        strokeWidth={2}
      />
    </svg>
  )
}

export default Speaker
