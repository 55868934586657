import { useEffect, useRef } from "react";

import { useUserMedia } from "src/media/hooks/useUserMedia";
import ErrorMessage from "src/shared/components/ErrorMessage";
import KeyboardShortcuts from "src/media/components/KeyboardShortcuts";
import Field from "src/shared/components/Field";
import Form from "src/shared/components/Form";
import useForm from "src/shared/hooks/useForm";
import Button from "src/shared/styles/Button";
import StyledSubmit from "src/shared/styles/Submit";
import Stream from "src/media/components/Stream";
import TestSpeakers from "src/media/components/TestSpeakers";
import AudioVisualizer from "src/media/components/AudioVisualizer";

export default function Settings() {
	const { handleSubmit } = useForm();
	const {
		localStream,
		devices,
		getDevices,
		getUserMedia,
		updatingMediaPreferences,
		updateMediaPreferences,
		updatingMediaPreferencesError,
	} = useUserMedia();

	const streamRef = useRef();

	useEffect(() => {
		getUserMedia();
		getDevices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = async (data: MediaDeviceInfo[]) => {
		updateMediaPreferences(data);
	};

	const videoInputs: any = devices
		?.filter((device: MediaDeviceInfo) => device.kind === "videoinput")
		.map((input) => ({ value: input.label, label: input.label }));

	const audioInputs: any = devices
		?.filter((device: MediaDeviceInfo) => device.kind === "audioinput")
		.map((input) => ({ value: input.label, label: input.label }));

	const audioOutputs: any = devices
		?.filter((device: MediaDeviceInfo) => device.kind === "audiooutput")
		.map((input) => ({ value: input.label, label: input.label }));

	return (
		<div style={{ display: "flex" }}>
			<div style={{ marginRight: 50 }}>
				<KeyboardShortcuts />
			</div>

			<Form
				loading={updatingMediaPreferences}
				onSubmit={handleSubmit(onSubmit)}
			>
				<ErrorMessage visible={Boolean(updatingMediaPreferencesError)}>
					<span style={{ flexGrow: 1 }}>{updatingMediaPreferencesError}</span>
				</ErrorMessage>

				<div style={{ display: "flex" }}>
					<Field
						label="Camera"
						name="camera"
						type="select"
						options={videoInputs}
					/>

					<div
						style={{
							flex: "0 0 70px",
							width: "70px",
							height: "70px",
							marginLeft: 20,
							borderRadius: "6px",
							overflow: "hidden",
						}}
					>
						<Stream ref={streamRef} stream={localStream} muted={true} />
					</div>
				</div>

				<div style={{ display: "flex" }}>
					<Field
						label="Microphone"
						name="microphone"
						type="select"
						options={audioInputs}
					/>

					<div
						style={{
							display: 'flex',
							flex: "0 0 70px",
							width: "70px",
							height: "70px",
							marginLeft: 20,
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: '#0D0D0D',
							borderRadius: '6px'
						}}
					>
						<AudioVisualizer stream={localStream} />
					</div>
				</div>

				<div style={{ display: "flex" }}>
					<Field
						label="Speaker"
						name="speaker"
						type="select"
						options={audioOutputs}
					/>

					<div
						style={{
							flex: "0 0 70px",
							width: "70px",
							height: "70px",
							marginLeft: 20,
						}}
					>
						<TestSpeakers />
					</div>
				</div>

				<StyledSubmit>
					<Button
						border="gradient"
						type="submit"
						disabled={updatingMediaPreferences}
					>
						Save
					</Button>
				</StyledSubmit>
			</Form>
		</div>
	);
}
