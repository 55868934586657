import { IRoom } from "src/rooms/room"
import { ActionMap } from "src/shared/shared"

export type UsersStateType = {
  usersAvailableToChat?: Partial<IUser>[]
} | undefined

export interface IUser {
  owner: string
  email: string
  phone: string
  username: string
  fullName: string
  profilePicture: string
  presence: string
  rooms: IRoom[]
}

export enum UsersActionTypes {
  ReceiveAvailableToChatUsers = "RECEIVE_AVAILABLE_TO_CHAT_USERS",
}

type UserPayload = {
  [UsersActionTypes.ReceiveAvailableToChatUsers]: {
    usersAvailableToChat?: Partial<IUser>[]
  }
}

export type UsersActions = ActionMap<UserPayload>[keyof ActionMap<UserPayload>]