import Flex from "../../shared/styles/Flex"
import RoomNewForm from "../components/forms/RoomNew"

export default function RoomNew() {
	return (
		<Flex css={{ height: "100vh", alignItems: "center", justifyContent: "center" }}>
			<RoomNewForm />
		</Flex>
	)
}
