import { Switch, useRouteMatch } from "react-router-dom"
import PrivateRoute from "src/shared/components/PrivateRoute"
import RoomNew from "../views/RoomNew"
import RoomSingle from "../views/RoomSingle"
import withRooms from "./withRooms"

function Rooms() {
	let { path } = useRouteMatch()

	return (
		<Switch>
			<PrivateRoute exact path={`${path}/new`} children={<RoomNew />} />
			<PrivateRoute path={`${path}/:roomId`} children={<RoomSingle />} />
		</Switch>

	)
}

export default withRooms(Rooms)
