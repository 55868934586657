import { createRef, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import Stream from "src/media/components/Stream"
import Flex from "src/shared/styles/Flex"
import useRooms from "src/rooms/hooks/useRoom"
import CallActions from "src/shared/components/CallActions"
import Messages from "src/messages/components/Messages"
import useAuth from "src/auth/hooks/useAuth"
import useKinesisMaster from "src/media/hooks/useKinesisMaster"
import useKinesisViewer from "src/media/hooks/useKinesisViewer"
// import useTranscribe from "src/media/hooks/useTranscribe"

export default function RoomSingle(props: any) {
	const { currentUser } = useAuth()
	const { getRoom, currentRoom } = useRooms()
	const { roomId }: any = useParams()
	// const transcribe = useTranscribe()

	const localStreamRef = useRef<HTMLVideoElement>(null)
	const localScreenStreamRef = useRef<HTMLVideoElement>(null)

	const [remoteStreamRefs, setRemoteStreamRefs] = useState<any>()

	let kinesis: any = null
	const isMaster = window.location.search === '?master'
	// eslint-disable-next-line react-hooks/rules-of-hooks
	kinesis = isMaster ? useKinesisMaster() : useKinesisViewer()

	useEffect(() => {
		kinesis.init()

		getRoom(roomId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setRemoteStreamRefs((remoteStreamRefs: any) => (
			Array(kinesis.remoteStreams.length).fill(null).map((_, i) => remoteStreamRefs[i] || createRef())
		));
	}, [kinesis.remoteStreams.length]);

	return (
		<Flex>
			<Flex css={{ flexFlow: 'column', height: "100vh", width: '100%' }}>
				<Flex style={{ flex: 1, alignItems: 'center', justifyContent: 'center', maxHeight: "calc(100vh - 90px)" }}>
					{kinesis.localStream && <Stream ref={localStreamRef} stream={kinesis.localStream} muted name={currentUser.fullName} flipped={true} />}

					{kinesis.localScreenStream && <Stream ref={localScreenStreamRef} stream={kinesis.localScreenStream} muted name={`${currentUser.fullName}'s screen`} />}

					{kinesis.remoteStreams &&
						Boolean(kinesis.remoteStreams.length) &&
						kinesis.remoteStreams.map((stream: any, index: number) => <Stream ref={remoteStreamRefs[index]} key={`stream-${index}`} stream={stream} flipped={true} />)}
				</Flex>

				<CallActions room={currentRoom} />
			</Flex>

			<Messages room={roomId} />
		</Flex>
	)
}
