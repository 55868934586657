import { useEffect, useState } from "react"
import { useKeys } from "../../shared/hooks/useKeys"
import RecordIcon from "../../shared/icons/Record"
import Button from "../../shared/styles/Button"
import useKinesisMaster from "../hooks/useKinesisMaster"
import useMediaRecorder from "../hooks/useMediaRecorder"

interface RecordProps { }

export default function Record(props: RecordProps) {
  const { record, stop } = useMediaRecorder()
  const { localStream } = useKinesisMaster()
  const [recording, setRecording] = useState<boolean>(false)
  const { keysDown, isPressed } = useKeys()

  useEffect(() => {
    if (localStream) {
      if (recording) {
        record(localStream)
      } else {
        stop()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recording])

  useEffect(() => {
    if (isPressed(['AltLeft', 'KeyR'])) {
      toggleRecord()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysDown])

  async function toggleRecord() {
    setRecording(!recording)
  }

  return (
    <Button onClick={toggleRecord} css={{ padding: 12, margin: 5, opacity: recording ? 1 : 0.5 }}>
      <RecordIcon />
    </Button>
  )
}
