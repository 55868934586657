import { useEffect, useState } from "react"
import FullScreenIcon from "src/shared/icons/FullScreen"
import Button from "src/shared/styles/Button"
import { useKeys } from "../hooks/useKeys"

interface FullScreenProps { }

export default function FullScreen(props: FullScreenProps) {
    const [fullScreenActive, setFullScreenActive] = useState<boolean>(false)
    const { keysDown, isPressed } = useKeys()

    useEffect(() => {
        if (isPressed(['AltLeft', 'KeyF'])) {
            toggleFullScreen()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keysDown])

    useEffect(() => {
        if (fullScreenActive) {
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
            }
        } else {
            if (document.fullscreenElement && document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    }, [fullScreenActive])

    async function toggleFullScreen() {
        setFullScreenActive(!fullScreenActive)
    }

    return (
        <Button onClick={toggleFullScreen} css={{ padding: 12, margin: 5, opacity: fullScreenActive ? 0.5 : 1 }}>
            <FullScreenIcon />
        </Button>
    )
}
