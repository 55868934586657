import { useContext, useRef } from "react";
import AWS from "aws-sdk";
import KinesisVideo from "aws-sdk/clients/kinesisvideo";
import { Role, SignalingClient } from "amazon-kinesis-video-streams-webrtc";
import Auth from "@aws-amplify/auth";
import { MediaContext } from "../contexts/media";
import { MediaActionTypes } from "../media";

export default function useKinesisMaster() {
  const { state, dispatch } = useContext(MediaContext);

  const region = process.env.REACT_APP_KINESIS_REGION || "eu-west-2";
  const ChannelName = process.env.REACT_APP_KINESIS_CHANNEL_NAME || "nero";
  const ChannelARN =
    process.env.REACT_APP_KINESIS_CHANNEL_ARN ||
    "arn:aws:kinesisvideo:eu-west-2:650668100444:channel/nero/1627560764041";

  const forceTURN = false;
  const natTraversalDisabled = false;
  const widescreen = false;
  const sendVideo = true;
  const sendAudio = true;
  const useTrickleICE = true;
  const openDataChannel = false;

  const resolution = widescreen
    ? { width: { ideal: 1280 }, height: { ideal: 720 } }
    : { width: { ideal: 640 }, height: { ideal: 480 } };

  const constraints = {
    video: sendVideo ? resolution : false,
    audio: sendAudio,
  };

  const onStatsReport = () => {};
  const onRemoteDataMessage = () => {};

  const kinesisVideoClient = useRef<any>(null);
  const signalingClient = useRef<any>(null);
  const channelARN = useRef<any>(null);
  const endpointsByProtocol = useRef<any>(null);
  const configuration = useRef<any>(null);
  const peerConnectionByClientId = useRef<any>({});
  const dataChannelByClientId = useRef<any>(null);
  const peerConnection = useRef<any>(null);
  const peerConnectionStatsInterval = useRef<any>(null);

  const localStreamRef = useRef<any>(null);
  const remoteStreamsRef = useRef<any>([]);

  async function init() {
    await newVideoClient();
    await getEndpoints();
    await createSignalingClient();
    await getICEServers();
  }

  /**
   * New Kinesis video client
   */
  async function newVideoClient() {
    const credentials = await Auth.currentUserCredentials();

    kinesisVideoClient.current = new KinesisVideo({
      credentials,
      region: process.env.REACT_APP_KINESIS_REGION,
      endpoint: undefined,
      correctClockSkew: true,
    });

    // Get signaling channel ARN
    const describeSignalingChannelResponse = await kinesisVideoClient.current
      .describeSignalingChannel({
        ChannelName,
      })
      .promise();
    channelARN.current =
      describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    console.log(`[MASTER] Channel ARN: `, channelARN);
  }

  /**
   * Get signal channel endpoints
   */
  async function getEndpoints() {
    const getSignalingChannelEndpointResponse = await kinesisVideoClient.current
      .getSignalingChannelEndpoint({
        ChannelARN: process.env.REACT_APP_KINESIS_CHANNEL_ARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ["WSS", "HTTPS"],
          Role: Role.MASTER,
        },
      })
      .promise();

    endpointsByProtocol.current =
      getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
        (endpoints: any, endpoint: any) => {
          endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
          return endpoints;
        },
        {}
      );

    console.log(`[MASTER] Endpoints: `, endpointsByProtocol);
  }

  /**
   * Create Signaling Client
   */
  async function createSignalingClient() {
    const credentials = await Auth.currentUserCredentials();

    // Create Signaling Client
    signalingClient.current = new SignalingClient({
      region,
      channelARN: channelARN.current,
      channelEndpoint: endpointsByProtocol.current.WSS,
      role: Role.MASTER,
      credentials,
    });

    signalingClient.current.on("open", async () => {
      console.log(`[MASTER] Connected to signaling service`);
    });

    signalingClient.current.on(
      "sdpOffer",
      async (offer: any, remoteClientId: any) => {
        console.log(
          `[MASTER] Received SDP offer from client: ` + remoteClientId
        );

        // Create a new peer connection using the offer from the given client
        peerConnection.current = new RTCPeerConnection(configuration.current);
        peerConnectionByClientId.current[remoteClientId] =
          peerConnection.current;

        if (openDataChannel) {
          dataChannelByClientId.current[remoteClientId] =
            peerConnection.current.createDataChannel("kvsDataChannel");
          peerConnection.current.ondatachannel = (event: any) => {
            event.channel.onmessage = onRemoteDataMessage;
          };
        }

        // Poll for connection stats
        if (!peerConnectionStatsInterval.current) {
          peerConnectionStatsInterval.current = setInterval(
            () => peerConnection.current.getStats().then(onStatsReport),
            1000
          );
        }

        // Send any ICE candidates to the other peer
        peerConnection.current.addEventListener(
          "icecandidate",
          ({ candidate }: any) => {
            if (candidate) {
              console.log(
                `[MASTER] Generated ICE candidate for client: ` + remoteClientId
              );

              // When trickle ICE is enabled, send the ICE candidates as they are generated.
              if (useTrickleICE) {
                console.log(
                  `[MASTER] Sending ICE candidate to client: ` + remoteClientId
                );
                signalingClient.current.sendIceCandidate(
                  candidate,
                  remoteClientId
                );
              }
            } else {
              console.log(
                `[MASTER] All ICE candidates have been generated for client: ` +
                  remoteClientId
              );

              // When trickle ICE is disabled, send the answer now that all the ICE candidates have ben generated.
              if (!useTrickleICE) {
                console.log(
                  `[MASTER] Sending SDP answer to client: ` + remoteClientId
                );
                signalingClient.current.sendSdpAnswer(
                  peerConnection.current.localDescription,
                  remoteClientId
                );
              }
            }
          }
        );

        // As remote tracks are received, add them to the remote view
        peerConnection.current.addEventListener("track", (event: any) => {
          console.log(
            `[MASTER] Received remote track from client: ` + remoteClientId
          );

          if (
            !remoteStreamsRef.current.find(
              (stream: MediaStream) => stream.id === event.streams[0].id
            )
          ) {
            remoteStreamsRef.current = [
              ...remoteStreamsRef.current,
              event.streams[0],
            ];

            dispatch({
              type: MediaActionTypes.ReceiveRemoteStreams,
              payload: {
                remoteStreams: remoteStreamsRef.current,
              },
            });
          }
        });

        localStreamRef.current.getTracks().forEach((track: any) => {
          peerConnection.current.addTrack(track, localStreamRef.current);
        });

        if (state.localScreenStream) {
          state.localScreenStream.getTracks().forEach((track: any) => {
            peerConnection.current.addTrack(track, state.localScreenStream);
          });
        }

        dispatch({
          type: MediaActionTypes.ReceiveLocalStream,
          payload: { localStream: localStreamRef.current },
        });

        await peerConnection.current.setRemoteDescription(offer);

        // Create an SDP answer to send back to the client
        console.log(
          `[MASTER] Creating SDP answer for client: ` + remoteClientId
        );
        await peerConnection.current.setLocalDescription(
          await peerConnection.current.createAnswer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
          })
        );

        // When trickle ICE is enabled, send the answer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
        if (useTrickleICE) {
          console.log(
            `[MASTER] Sending SDP answer to client: ` + remoteClientId
          );
          signalingClient.current.sendSdpAnswer(
            peerConnection.current.localDescription,
            remoteClientId
          );
        }
        console.log(
          `[MASTER] Generating ICE candidates for client: ` + remoteClientId
        );
      }
    );

    signalingClient.current.on(
      "iceCandidate",
      async (candidate: any, remoteClientId: any) => {
        console.log(
          `[MASTER] Received ICE candidate from client: ` + remoteClientId
        );

        // Add the ICE candidate received from the client to the peer connection
        const peerConnection = peerConnectionByClientId.current[remoteClientId];
        peerConnection.addIceCandidate(candidate);
      }
    );

    signalingClient.current.on("close", () => {
      console.log(`[MASTER] Disconnected from signaling channel`);
    });

    signalingClient.current.on("error", () => {
      console.error(`[MASTER] Signaling client error`);
    });

    console.log(`[MASTER] Starting master connection`);
    signalingClient.current.open();
  }

  /**
   * Get ICE servers
   */
  async function getICEServers() {
    const credentials = await Auth.currentUserCredentials();

    // Get ICE server configuration
    const kinesisVideoSignalingChannelsClient =
      new AWS.KinesisVideoSignalingChannels({
        region,
        endpoint: endpointsByProtocol.current.HTTPS,
        correctClockSkew: true,
        credentials,
      });

    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN,
      })
      .promise();

    const iceServers = [];

    if (!natTraversalDisabled && !forceTURN) {
      iceServers.push({
        urls: `stun:stun.kinesisvideo.${region}.amazonaws.com:443`,
      });
    }

    if (!natTraversalDisabled) {
      getIceServerConfigResponse.IceServerList?.forEach((iceServer) =>
        iceServers.push({
          urls: iceServer.Uris,
          username: iceServer.Username,
          credential: iceServer.Password,
        })
      );
    }

    console.log(`[MASTER] ICE servers: `, iceServers);

    configuration.current = {
      iceServers,
      iceTransportPolicy: forceTURN ? "relay" : "all",
    };

    // Get a stream from the webcam and display it in the local view
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      localStreamRef.current = stream;

      dispatch({
        type: MediaActionTypes.ReceiveLocalStream,
        payload: { localStream: localStreamRef.current },
      });
    } catch (e) {
      console.error(`[MASTER] Could not find webcam`);
    }
  }

  return {
    ...state,
    init,
  };
}
