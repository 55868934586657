import { IRoom } from "src/rooms/room"
import ErrorMessage from "src/shared/components/ErrorMessage"
import Field from "../../../shared/components/Field"
import Form from "../../../shared/components/Form"
import useForm from "../../../shared/hooks/useForm"
import Button from "../../../shared/styles/Button"
import StyledSubmit from "../../../shared/styles/Submit"
import useRoom from "../../hooks/useRoom"

export default function RoomNew() {
	const { handleSubmit } = useForm()
	const { creatingRoom, createRoomError, createdRoom, createRoom } = useRoom()

	const onSubmit = async (data: IRoom) => {
		createRoom(data)
	}

	return (
		<Form loading={creatingRoom} success={Boolean(createdRoom)} handleSubmit={handleSubmit(onSubmit)}>
			<ErrorMessage visible={Boolean(createRoomError)}>
				<span style={{ flexGrow: 1 }}>{createRoomError}</span>
			</ErrorMessage>

			<Field label="Name" name="name" type="text" required />

			<StyledSubmit>
				<Button border="gradient" type="submit" disabled={creatingRoom}>
					Create room
				</Button>
			</StyledSubmit>
		</Form>
	)
}
