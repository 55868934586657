import React from "react"
import StyledForm from "../styles/Form"
import StyledLoader from "../styles/Loader"
import Loader from "../icons/Loader"

interface FormProps extends React.ComponentPropsWithoutRef<"form"> {
	loading?: boolean
	success?: boolean
	handleSubmit?: (e: any) => void
}

export default function Form(props: FormProps) {

	function onSubmit(e: any) {
		e.preventDefault()

		props.handleSubmit && props.handleSubmit(e)
	}

	return (
		<>
			<StyledLoader loading={props.loading} success={props.success}>
				<Loader />
			</StyledLoader>

			<StyledForm onSubmit={onSubmit} loading={props.loading} success={props.success}>{props.children}</StyledForm>
		</>
	)
}
