function Loader(props: any) {
	return (
		<svg viewBox="0 0 64 64" {...props} width={24}>
			<g stroke="white" strokeWidth={4} strokeLinecap="round">
				<path d="M32 15V3">
					<animate
						attributeName="stroke-opacity"
						dur="1000ms"
						values="1;.85;.7;.65;.55;.45;.35;.25;.15;.1;0;1"
						repeatCount="indefinite"
					/>
				</path>
				<path d="M40.5 17.278l6-10.393">
					<animate
						attributeName="stroke-opacity"
						dur="1000ms"
						values="0;1;.85;.7;.65;.55;.45;.35;.25;.15;.1;0"
						repeatCount="indefinite"
					/>
				</path>
				<path d="M46.722 23.5l10.393-6">
					<animate
						attributeName="stroke-opacity"
						dur="1000ms"
						values=".1;0;1;.85;.7;.65;.55;.45;.35;.25;.15;.1"
						repeatCount="indefinite"
					/>
				</path>
				<path d="M49 32h12">
					<animate
						attributeName="stroke-opacity"
						dur="1000ms"
						values=".15;.1;0;1;.85;.7;.65;.55;.45;.35;.25;.15"
						repeatCount="indefinite"
					/>
				</path>
				<path d="M46.722 40.5l10.393 6">
					<animate
						attributeName="stroke-opacity"
						dur="1000ms"
						values=".25;.15;.1;0;1;.85;.7;.65;.55;.45;.35;.25"
						repeatCount="indefinite"
					/>
				</path>
				<path d="M40.5 46.722l6 10.393">
					<animate
						attributeName="stroke-opacity"
						dur="1000ms"
						values=".35;.25;.15;.1;0;1;.85;.7;.65;.55;.45;.35"
						repeatCount="indefinite"
					/>
				</path>
				<path d="M32 49v12">
					<animate
						attributeName="stroke-opacity"
						dur="1000ms"
						values=".45;.35;.25;.15;.1;0;1;.85;.7;.65;.55;.45"
						repeatCount="indefinite"
					/>
				</path>
				<path d="M23.5 46.722l-6 10.393">
					<animate
						attributeName="stroke-opacity"
						dur="1000ms"
						values=".55;.45;.35;.25;.15;.1;0;1;.85;.7;.65;.55"
						repeatCount="indefinite"
					/>
				</path>
				<path d="M17.278 40.5l-10.393 6">
					<animate
						attributeName="stroke-opacity"
						dur="1000ms"
						values=".65;.55;.45;.35;.25;.15;.1;0;1;.85;.7;.65"
						repeatCount="indefinite"
					/>
				</path>
				<path d="M15 32H3">
					<animate
						attributeName="stroke-opacity"
						dur="1000ms"
						values=".7;.65;.55;.45;.35;.25;.15;.1;0;1;.85;.7"
						repeatCount="indefinite"
					/>
				</path>
				<path d="M17.278 23.5l-10.393-6">
					<animate
						attributeName="stroke-opacity"
						dur="1000ms"
						values=".85;.7;.65;.55;.45;.35;.25;.15;.1;0;1;.85"
						repeatCount="indefinite"
					/>
				</path>
				<path d="M23.5 17.278l-6-10.393">
					<animate
						attributeName="stroke-opacity"
						dur="1000ms"
						values="1;.85;.7;.65;.55;.45;.35;.25;.15;.1;0;1"
						repeatCount="indefinite"
					/>
				</path>
			</g>
		</svg>
	)
}

export default Loader
