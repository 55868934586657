import Flex from "../../shared/styles/Flex"
import SignInForm from "../components/forms/SignIn"

export default function SignIn() {
	return (
		<Flex css={{ height: "100vh", alignItems: "center", justifyContent: "center" }}>
			<SignInForm />
		</Flex>
	)
}
