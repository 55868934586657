import { ActionMap } from "src/shared/shared"
import { IUser } from "src/users/user"

export type RoomsStateType = {
  rooms?: Partial<IRoom>[]
  currentRoom?: Partial<IRoom>
  creatingRoom?: boolean
  createdRoom?: Partial<IRoom>
  createRoomError?: string
} | undefined

export interface IRoom {
  id: string
  name: string
  currentTopic: string
  users: Partial<IUser>[]
}

export enum RoomsActionTypes {
  CreatingRoom = "CREATING_ROOM",
  CreatedRoom = "CREATED_ROOM",
  CreateRoomError = "CREATE_ROOM_ERROR",
  ReceiveRooms = "RECEIVE_ROOMS",
  ReceiveCurrentRoom = "RECEIVE_CURRENT_ROOM",
}

type RoomsPayload = {
  [RoomsActionTypes.CreatingRoom]: {
    creatingRoom: boolean
  },
  [RoomsActionTypes.CreatedRoom]: {
    createdRoom: Partial<IRoom>
  },
  [RoomsActionTypes.CreateRoomError]: {
    createRoomError: string
  },
  [RoomsActionTypes.ReceiveRooms]: {
    rooms?: Partial<IRoom>[]
  }
  [RoomsActionTypes.ReceiveCurrentRoom]: {
    currentRoom?: Partial<IRoom>
  }
}

export type RoomsActions = ActionMap<RoomsPayload>[keyof ActionMap<RoomsPayload>]