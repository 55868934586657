import * as React from "react"

function EndCall(props: any) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 6C8.392 5.969.957 7.36.076 13.19c-.425 2.818 1.01 3.984 2.385 3.79l4.002-1.311c.62-.203 1.043-.812 1.043-1.502v-1.865c0-.465.256-.887.671-1.031A13.564 13.564 0 0112 10.567c1.59.058 2.97.409 3.823.704.415.144.671.566.671 1.03v1.866c0 .69.423 1.3 1.043 1.502l4.002 1.31c1.376.195 2.81-.971 2.385-3.789C23.044 7.36 15.608 5.968 12 6z"
        fill="#fff"
      />
    </svg>
  )
}

export default EndCall
