import { useContext } from "react";
import { MediaContext } from "../contexts/media";
import { MediaActionTypes } from "../media";

export function useUserMedia() {
  const { state, dispatch } = useContext(MediaContext);

  /**
   * Get Permissions
   */
  async function getPermissions(permissionDesc: PermissionDescriptor) {
    const permissions: any = navigator.permissions.query(permissionDesc);

    dispatch({
      type: MediaActionTypes.ReceivePermissions,
      payload: { permissions },
    });
  }

  /**
   * Update Media Preferences
   */
  async function updateMediaPreferences(preferences: any) {}

  /**
   * Get Devices
   */
  async function getDevices() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    dispatch({ type: MediaActionTypes.ReceiveDevices, payload: { devices } });
  }

  /**
   * Update Constraints
   */
  async function updateConstraints(constraints: MediaStreamConstraints) {
    dispatch({
      type: MediaActionTypes.ReceiveConstraints,
      payload: { constraints },
    });
  }

  /**
   * Request permissions and get access to the webcam & audio
   */
  async function getUserMedia(
    steamConstraints: MediaStreamConstraints = { audio: true, video: true }
  ) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(
        steamConstraints
      );

      dispatch({
        type: MediaActionTypes.ReceiveLocalStream,
        payload: { localStream: stream },
      });
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Request permissions and get access to the screen & audio
   */
  async function getDisplayMedia(
    steamConstraints: MediaStreamConstraints = { audio: true, video: true }
  ) {
    try {
      const localScreenStream = await (
        navigator.mediaDevices as any
      ).getDisplayMedia(steamConstraints);

      dispatch({
        type: MediaActionTypes.ReceiveLocalScreenStream,
        payload: { localScreenStream },
      });

      localScreenStream.getVideoTracks()[0].onended = function () {
        dispatch({
          type: MediaActionTypes.ReceiveLocalScreenStream,
          payload: { localScreenStream: undefined },
        });
      };
    } catch (err) {
      console.error(err);
    }
  }

  return {
    ...state,
    getDevices,
    getUserMedia,
    getDisplayMedia,
    getPermissions,
    updateConstraints,
    updateMediaPreferences,
  };
}
