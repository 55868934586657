function Subtitles(props: any) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7a3 3 0 013-3h14a3 3 0 013 3v10a3 3 0 01-3 3H5a3 3 0 01-3-3V7zm3 9a1 1 0 011-1h4a1 1 0 110 2H6a1 1 0 01-1-1zm8-1a1 1 0 100 2h5a1 1 0 100-2h-5zm-7-2a1 1 0 011-1h5a1 1 0 110 2H7a1 1 0 01-1-1zm9-1a1 1 0 100 2h2a1 1 0 100-2h-2z"
        fill="#fff"
      />
    </svg>
  )
}

export default Subtitles
