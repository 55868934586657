import styled from "./theme";

const StyledLoader = styled('div', {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  opacity: 0,
  zIndex: -1,
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'center',
  transform: 'scale(0)',
  transition: 'all .3s',

  variants: {
    loading: {
      true: {
        zIndex: 10,
        opacity: 1,
        transform: 'scale(1)'
      }
    },
    success: {
      true: {
        opacity: 0,
        transform: 'scale(0)'
      }
    }
  }
});

export default StyledLoader