import { useEffect } from "react"
import useAuth from "../hooks/useAuth"

export default function SignOut() {
	const { signOut } = useAuth()

	useEffect(() => {
		async function logout() {
			await signOut()
		}

		logout()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return null
}
