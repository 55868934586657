import { ISignIn } from "../../../auth/auth"
import useAuth from "../../../auth/hooks/useAuth"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import Field from "../../../shared/components/Field"
import Form from "../../../shared/components/Form"
import useForm from "../../../shared/hooks/useForm"
import Button from "../../../shared/styles/Button"
import StyledSubmit from "../../../shared/styles/Submit"

export default function ReserveUsernameForm() {
	const { handleSubmit } = useForm()
	const { signupError, signedUp, signingUp, signUp } = useAuth()

	const onSubmit = async (data: ISignIn) => {
		await signUp(data)
	}

	return (
		<Form loading={signingUp} success={signedUp} onSubmit={handleSubmit(onSubmit)}>
			<ErrorMessage visible={Boolean(signupError)}>
				<span style={{ flexGrow: 1 }}>{signupError}</span>
			</ErrorMessage>
			<Field label="Username" name="username" type="text" tabIndex={1} required />
			<Field label="Email" name="email" type="email" tabIndex={2} required />
			<StyledSubmit>
				<Button border="gradient" type="submit" disabled={signingUp} tabIndex={6}>
					Reserve
				</Button>
			</StyledSubmit>
		</Form>
	)
}
