import { FormEvent, FormEventHandler } from "react"

export function useForm() {

  /**
   * Handle submit 
   * 
   * @param callback 
   * @returns 
   */
  function handleSubmit(callback: any): FormEventHandler<HTMLFormElement> {
    return async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const elements: HTMLFormControlsCollection = (e.target as HTMLFormElement).elements

      let data: { [key: string]: any } = {}
      for (let element of elements as any) {
        if (element.name && element.type !== 'submit') {
          if (element.type === 'file') {
            data[element.name] = element.files
          } else {
            data[element.name] = element.value
          }
        }
      }

      callback(data)
    }
  }

  return {
    handleSubmit
  }
}

export default useForm