import { ActionMap } from "src/shared/shared";
import { IUser } from "src/users/user";
export type MessagesStateType =
  | {
      messages?: Partial<IMessage>[];
      creatingMessage?: boolean;
      createdMessage?: Partial<IMessage>;
      createMessageError?: string;
    }
  | undefined;

export interface IMessage {
  id: string;
  text: string;
  owner: string;
  room: string;
  createdAt: string;
  user: Partial<IUser>;
  isCurrentUser: boolean;
}

export enum MessagesActionTypes {
  CreatingMessage = "CREATING_MESSAGE",
  CreatedMessage = "CREATED_MESSAGE",
  CreateMessageError = "CREATE_MESSAGE_ERROR",
  ReceiveMessages = "RECEIVE_MESSAGES",
}

type MessagesPayload = {
  [MessagesActionTypes.CreatingMessage]: {
    creatingMessage: boolean;
  };
  [MessagesActionTypes.CreatedMessage]: {
    createdMessage: Partial<IMessage>;
  };
  [MessagesActionTypes.CreateMessageError]: {
    createMessageError: string;
  };
  [MessagesActionTypes.ReceiveMessages]: {
    messages?: Partial<IMessage>[];
  };
};

export type MessagesActions =
  ActionMap<MessagesPayload>[keyof ActionMap<MessagesPayload>];
