import dayjs from "dayjs"
import relativeTime from 'dayjs/plugin/relativeTime'
import { useState } from "react"
import { linkify } from "src/shared/utils/url"

import { IMessage } from "../message"
import * as Styles from "../styles/MessageListItem"

dayjs.extend(relativeTime)

interface IMessageListItem {
    message: Partial<IMessage>
}

export default function MessageListItem({ message }: IMessageListItem) {
    const [detailsVisible, setDetailsVisible] = useState<boolean>(false)

    function onClick() {
        setDetailsVisible(!detailsVisible)
    }

    return (
        <Styles.Container currentUser={message.isCurrentUser} detailsVisible={detailsVisible}>
            <Styles.Inner onClick={onClick}>
                <Styles.User>{message.user?.fullName?.split(' ')[0]}</Styles.User>
                <Styles.Text>
                    <Styles.TextInner dangerouslySetInnerHTML={{ __html: linkify(message.text) }} />
                </Styles.Text>
                <Styles.CreatedAt>{dayjs(message.createdAt).format("HH:mm")}</Styles.CreatedAt>
            </Styles.Inner>
        </Styles.Container>
    )
}
