import ErrorMessage from "src/shared/components/ErrorMessage"
import Field from "src/shared/components/Field"
import Form from "src/shared/components/Form"
import useForm from "src/shared/hooks/useForm"
import Button from "src/shared/styles/Button"
import StyledSubmit from "src/shared/styles/Submit"
import { IVerify } from "src/auth/auth"
import useAuth from "src/auth/hooks/useAuth"
import { useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import { delay } from "src/shared/utils/delay"

export default function VerifyForm() {
	const inputRef = useRef<HTMLInputElement>(null)
	const history = useHistory()
	const { handleSubmit } = useForm()
	const { verifyError, signedIn, verifying, signingIn, verify, signupUserData, getCurrentUser } = useAuth()

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		async function navigateToHome() {
			// Wait for form to animate out
			await delay(300)

			// Fetch current user, will redirect
			await getCurrentUser()
		}

		// After the code has been verified, the user is signed in
		if (signedIn) {
			navigateToHome()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, signedIn])

	const onSubmit = async (data: IVerify) => {
		if (signupUserData) {
			await verify({ code: data.code, signupUserData })
		} else {
			throw new Error("Signup user data not found")
		}
	}

	return (
		<Form loading={verifying || signingIn} success={signedIn} handleSubmit={handleSubmit(onSubmit)}>
			<ErrorMessage visible={Boolean(verifyError)}>
				<span style={{ flexGrow: 1 }}>{verifyError}</span>
			</ErrorMessage>

			<Field ref={inputRef} label="Code" name="code" type="text" required />

			<StyledSubmit>
				<Button border="gradient" type="submit" disabled={verifying}>
					Verify
				</Button>
			</StyledSubmit>
		</Form>
	)
}
