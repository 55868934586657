import { useEffect, useState } from "react"
import Settings from "src/media/views/Settings"
import SettingsIcon from "src/shared/icons/Settings"
import Button from "src/shared/styles/Button"
import { useKeys } from "../hooks/useKeys"

interface SettingsProps { }

export default function SettingsButton(props: SettingsProps) {
    const [settingsVisible, setSettingsVisible] = useState<boolean>(false)
    const { keysDown, isPressed } = useKeys()

    useEffect(() => {
        if (isPressed(['AltLeft', 'KeyP'])) {
            toggleSettings()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keysDown])

    async function toggleSettings() {
        setSettingsVisible(!settingsVisible)
    }

    return (
        <>
            <Button onClick={toggleSettings} css={{ padding: 12, margin: 5 }}>
                <SettingsIcon />
            </Button>

            {settingsVisible && <Settings onClose={toggleSettings} />}
        </>
    )
}
