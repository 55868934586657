import { useEffect } from "react"
import MessageNew from "src/messages/components/forms/MessageNew"
import MessageList from "src/messages/components/MessageList"
import useMessages from "src/messages/hooks/useMessage"
import StyledMessages from "src/messages/styles/Messages"

interface IMessages {
    room?: string
}

export default function Messages({ room }: IMessages) {
    const { messages, getMessages, subscribeToMessages } = useMessages()

    useEffect(() => {
        getMessages(room)

        const subscription: any = subscribeToMessages()

        return () => {
            subscription.unsubscribe && subscription.unsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <StyledMessages>
            <MessageList messages={messages} />
            <MessageNew room={room} />
        </StyledMessages>
    )
}