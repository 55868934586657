import Flex from "../../shared/styles/Flex"
import VerifyForm from "../components/forms/Verify"

export default function Verify() {
	return (
		<Flex css={{ height: "100vh", alignItems: "center", justifyContent: "center" }}>
			<VerifyForm />
		</Flex>
	)
}
