import { useReducer } from "react"

import { RoomsReducer } from "src/rooms/reducers/rooms"
import { RoomsContext, initialRoomsState } from "src/rooms/contexts/rooms"

export default function withRooms(WrappedComponent: any) {
	return (props: any) => {
		const [state, dispatch] = useReducer(RoomsReducer, initialRoomsState)

		return (
			<RoomsContext.Provider value={{ state, dispatch }}>
				<WrappedComponent {...props} />
			</RoomsContext.Provider>
		)
	}
}
