import styled from "./theme";

export const Container = styled("div", {
  top: 0,
  left: 0,
  zIndex: 10,
  width: "100vw",
  height: "100vh",
  display: "flex",
  position: "fixed",
  alignItems: "center",
  justifyContent: "center",
  background: "rgba(0,0,0,0.5)",
});

export const Box = styled("div", {
  zIndex: 11,
  padding: 20,
  display: "flex",
  borderRadius: 20,
  position: "relative",
  background: "rgba(30,30,30,1)",
});
