import * as React from "react"

function Tick(props: any) {
	return (
		<svg
			id="prefix__e5LilxZPgWR1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 12 11"
			shapeRendering="geometricPrecision"
			textRendering="geometricPrecision"
			{...props}>
			<style>
				{
					"@keyframes e5LilxZPgWR3_s_do{0%{stroke-dashoffset:17;animation-timing-function:cubic-bezier(.55,.085,.68,.53)}to{stroke-dashoffset:0}}"
				}
			</style>
			<defs>
				<linearGradient
					id="prefix__e5LilxZPgWR2-fill"
					x1={12.611}
					y1={0}
					x2={2.587}
					y2={11.026}
					spreadMethod="pad"
					gradientUnits="userSpaceOnUse">
					<stop id="prefix__e5LilxZPgWR2-fill-0" offset="0%" stopColor="#00EA17" />
					<stop id="prefix__e5LilxZPgWR2-fill-1" offset="100%" stopColor="#00EADC" />
				</linearGradient>
				<linearGradient
					id="prefix__e5LilxZPgWR3-stroke"
					x1={-0.1}
					y1={1.111}
					x2={1.1}
					y2={-0.111}
					spreadMethod="pad"
					gradientUnits="objectBoundingBox">
					<stop id="prefix__e5LilxZPgWR3-stroke-0" offset="0%" stopColor="#00EADC" />
					<stop id="prefix__e5LilxZPgWR3-stroke-1" offset="100%" stopColor="#00EA17" />
				</linearGradient>
			</defs>
			<path
				d="M.997 6l3.75 4L11 1"
				transform="translate(.002)"
				fill="none"
				stroke="url(#prefix__e5LilxZPgWR3-stroke)"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit={1}
				strokeDashoffset={17}
				strokeDasharray="17,100"
				style={{
					animation: "e5LilxZPgWR3_s_do 300ms linear 1 normal forwards"
				}}
			/>
		</svg>
	)
}

export default Tick
