import ErrorMessage from "src/shared/components/ErrorMessage"
import Field from "src/shared/components/Field"
import Form from "src/shared/components/Form"
import useForm from "src/shared/hooks/useForm"
import Button from "src/shared/styles/Button"
import StyledSubmit from "src/shared/styles/Submit"
import { IResetPassword } from "src/auth/auth"
import useAuth from "src/auth/hooks/useAuth"
import { useEffect, useRef } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { delay } from "src/shared/utils/delay"
import StyledSeperator from "src/shared/styles/Seperator"
import Text from "src/shared/components/Text"

export default function ResetPasswordForm() {
  const location = useLocation()
  const inputRef = useRef<HTMLInputElement>(null)
  const history = useHistory()
  const { handleSubmit } = useForm()
  const { resetPasswordError, resettingPassword, passwordReset, resetPassword } = useAuth()
  const { email } = location.state as any

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function navigateToSignIn() {
      // Wait for form to animate out
      await delay(300)

      history.push('/signin')
    }

    // After the code has been verified, the user is signed in
    if (passwordReset) {
      navigateToSignIn()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, passwordReset])

  const onSubmit = async ({ code, password, confirmPassword }: IResetPassword) => {
    await resetPassword(code, email, password, confirmPassword)
  }

  return (
    <Form loading={resettingPassword} success={passwordReset} handleSubmit={handleSubmit(onSubmit)}>
      <ErrorMessage visible={Boolean(resetPasswordError)}>
        <span style={{ flexGrow: 1 }}>{resetPasswordError}</span>
      </ErrorMessage>

      <Text>A verification code has been sent to your email address.</Text>

      <Field ref={inputRef} label="Verification code" name="code" type="text" required />

      <StyledSeperator />

      <Field label="New password" name="password" type="password" required />
      <Field label="Confirm password" name="confirmPassword" type="password" required />

      <StyledSubmit>
        <Button border="gradient" type="submit" disabled={resettingPassword}>
          Reset password
        </Button>
      </StyledSubmit>
    </Form>
  )
}
