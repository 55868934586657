import React, { createContext } from "react"

import { RoomsStateType, RoomsActions } from "src/rooms/room"

export const initialRoomsState: RoomsStateType = {
  rooms: []
}

export const RoomsContext = createContext<{
  state: RoomsStateType
  dispatch: React.Dispatch<RoomsActions>
}>({
  state: initialRoomsState,
  dispatch: () => null
})
