import React, { createContext } from "react"

import { UsersStateType, UsersActions } from "src/users/user"

export const initialUsersState: UsersStateType = {
  usersAvailableToChat: []
}

export const UsersContext = createContext<{
  state: UsersStateType
  dispatch: React.Dispatch<UsersActions>
}>({
  state: initialUsersState,
  dispatch: () => null
})
