function Error(props: any) {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect width={24} height={24} rx={8} fill="url(#prefix__paint0_linear)" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.752 6.985l-.628 5.023a1.133 1.133 0 01-2.248 0l-.628-5.023a1.765 1.765 0 113.504 0zM12 15a2 2 0 100 4 2 2 0 000-4z"
				fill="#fff"
			/>
			<defs>
				<linearGradient id="prefix__paint0_linear" x1={24} y1={0} x2={0} y2={24} gradientUnits="userSpaceOnUse">
					<stop stopColor="#FF004D" />
					<stop offset={1} stopColor="#FF1E01" />
				</linearGradient>
			</defs>
		</svg>
	)
}

export default Error
