import {
  MediaActionTypes,
  MediaStateType,
  MediaActions,
} from "src/media/media";

export const MediaReducer = (
  prevState: MediaStateType,
  action: MediaActions
): MediaStateType => {
  switch (action.type) {
    case MediaActionTypes.ReceiveDevices:
      return {
        ...prevState,
        devices: action.payload.devices,
      };
    case MediaActionTypes.ReceivePermissions:
      return {
        ...prevState,
        permissions: action.payload.permissions,
      };
    case MediaActionTypes.ReceiveConstraints:
      return {
        ...prevState,
        constraints: action.payload.constraints,
      };
    case MediaActionTypes.ReceiveLocalStream:
      return {
        ...prevState,
        localStream: action.payload.localStream,
      };
    case MediaActionTypes.ReceiveLocalScreenStream:
      return {
        ...prevState,
        localScreenStream: action.payload.localScreenStream,
      };
    case MediaActionTypes.ReceiveRemoteStreams:
      return {
        ...prevState,
        remoteStreams: action.payload.remoteStreams,
      };
  }
};
