import { useContext } from "react"
import { API } from "aws-amplify";
import * as mutations from 'src/graphql/mutations'
import * as queries from 'src/graphql/queries';
import { RoomsContext } from "../contexts/rooms"
import { IRoom, RoomsActionTypes } from "../room"
import { useHistory } from "react-router-dom";

function useRooms() {
  const history = useHistory()
  const { state, dispatch } = useContext(RoomsContext)

  /**
   * Get room
   */
  async function getRoom(id: string) {
    const response: any = await API.graphql({ query: queries.getRoom, variables: { id } });
    dispatch({ type: RoomsActionTypes.ReceiveCurrentRoom, payload: { currentRoom: response.data.getRoom } })
  }

  /**
   * Get rooms
   */
  async function getRooms() {
    const response: any = await API.graphql({ query: queries.listRooms });
    dispatch({ type: RoomsActionTypes.ReceiveRooms, payload: { rooms: response.data.listRooms.items } })
  }

  /**
   * Create room
   * 
   * @param data 
   */
  async function createRoom(data: Partial<IRoom>) {
    dispatch({ type: RoomsActionTypes.CreatingRoom, payload: { creatingRoom: true } })

    try {
      const response: any = await API.graphql({ query: mutations.createRoom, variables: { input: data } });
      dispatch({ type: RoomsActionTypes.CreatedRoom, payload: { createdRoom: response.data.createRoom } })

      history.push(`/rooms/${response.data.createRoom.id}/?master`, { data })
    } catch (error) {
      console.log('Error signing in', error);
    }

    dispatch({ type: RoomsActionTypes.CreatingRoom, payload: { creatingRoom: false } })
  }

  return {
    ...state,
    getRoom,
    getRooms,
    createRoom
  }
}

export default useRooms