import styled from "./theme";

const Button = styled("button", {
  display: "flex",
  alignItems: "center",
  margin: "2px",
  padding: "$1 $1 8px $1",
  borderRadius: "8px",
  fontSize: "$3",
  fontFamily: "$sofia",
  lineHeight: "1",
  border: "0",
  position: "relative",
  appearance: "none",
  background: "#0D0D0D",
  color: "$white",
  fontWeight: "500",
  cursor: "pointer",
  transition: "all .2s",
  transformStyle: "preserve-3d",
  userSelect: "none",

  "&:hover": {
    background: "#0D0D0D",
    boxShadow: "0 0 0 2px rgba(255,255,255,0.1)",
  },

  "&:focus": {
    outline: 0,
    boxShadow: "0 0 0 2px rgba(255,255,255,0.1)",
  },

  "&:active": {
    transform: "scale(0.95)",
  },

  variants: {
    border: {
      gradient: {
        "&::before": {
          content: `''`,
          display: "block",
          backgroundImage: "linear-gradient(to top right, #FA00FF, #FF0000)",
          position: "absolute",
          top: "-2px",
          left: "-2px",
          width: "calc(100% + 4px)",
          height: "calc(100% + 4px)",
          borderRadius: "10px",
          zIndex: -1,
          transform: "translateZ(-1px)",
          transition: "all .3s",
        },

        "&::after": {
          opacity: 0,
          content: `''`,
          display: "block",
          backgroundImage: "linear-gradient(to top right, #FA00FF, #FF0000)",
          position: "absolute",
          top: "-2px",
          left: "-2px",
          width: "calc(100% + 4px)",
          height: "calc(100% + 4px)",
          borderRadius: "16px",
          zIndex: -1,
          transform: "translateZ(-2px)",
          transition: "all .3s",
          filter: "blur(6px)",
        },

        "&:hover": {
          background: "#0D0D0D",
          boxShadow: "none",

          "&::after": {
            opacity: 1,
          },
        },

        "&:focus": {
          outline: 0,
          boxShadow: "none",

          "&::after": {
            opacity: 1,
          },
        },

        "&:active": {
          transform: "scale(0.95)",
        },
      },
    },
  },
});

export default Button;
