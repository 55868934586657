import React, { createContext } from "react";

import { MessagesStateType, MessagesActions } from "src/messages/message";

export const initialMessagesState: MessagesStateType = {
  messages: [],
};

export const MessagesContext = createContext<{
  state: MessagesStateType;
  dispatch: React.Dispatch<MessagesActions>;
}>({
  state: initialMessagesState,
  dispatch: () => null,
});
