import { useState } from "react"
import { InputHTMLAttributes } from "react"
import Tick from "../icons/Tick"
import StyledCheckbox, { Input } from "../styles/Checkbox"

export default function Checkbox(props: InputHTMLAttributes<HTMLInputElement>) {
	const [checked, setChecked] = useState<boolean>(false)

	function onChange(e: any) {
		setChecked(e.target.checked)
	}

	return (
		<StyledCheckbox>
			{checked && (
				<Tick
					style={{ position: "absolute", top: 5, left: 5, width: 10, height: 10, zIndex: 2, pointerEvents: "none" }}
				/>
			)}
			<Input {...props} onChange={onChange} />
		</StyledCheckbox>
	)
}
