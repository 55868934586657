import ErrorMessage from "src/shared/components/ErrorMessage"
import Field from "src/shared/components/Field"
import Form from "src/shared/components/Form"
import useForm from "src/shared/hooks/useForm"
import Button from "src/shared/styles/Button"
import StyledSubmit from "src/shared/styles/Submit"
import { IForgot } from "src/auth/auth"
import useAuth from "src/auth/hooks/useAuth"
import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { delay } from "src/shared/utils/delay"

export default function ForgotForm() {
  const history = useHistory()
  const { handleSubmit } = useForm()
  const [email, setEmail] = useState<string>()
  const inputRef = useRef<HTMLInputElement>(null)
  const { resetPasswordEmailError, sendingPasswordResetEmail, resetPasswordEmailSent, sendResetPasswordEmail } = useAuth()

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function navigateToResetPassword() {
      // Wait for form to animate out
      await delay(300)

      history.push('/reset-password', { email })
    }

    // After the code has been verified, the user is signed in
    if (resetPasswordEmailSent) {
      navigateToResetPassword()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, resetPasswordEmailSent])

  const onSubmit = async ({ email }: IForgot) => {
    setEmail(email)
    await sendResetPasswordEmail(email)
  }

  return (
    <Form loading={sendingPasswordResetEmail} success={resetPasswordEmailSent} handleSubmit={handleSubmit(onSubmit)}>
      <ErrorMessage visible={Boolean(resetPasswordEmailError)}>
        <span style={{ flexGrow: 1 }}>{resetPasswordEmailError}</span>
      </ErrorMessage>

      <Field ref={inputRef} label="Email" name="email" type="email" required />

      <StyledSubmit>
        <Button border="gradient" type="submit" disabled={sendingPasswordResetEmail}>
          Reset password
        </Button>
      </StyledSubmit>
    </Form>
  )
}
