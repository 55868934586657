import { Redirect, Route } from "react-router-dom"
import useAuth from "../../auth/hooks/useAuth"
import useQuery from "../hooks/useQuery"

export default function AuthRoute({ children, ...rest }: any) {
	const query = useQuery()
	const { currentUser } = useAuth()

	return (
		<Route
			{...rest}
			render={({ location }) =>
				!currentUser ? (
					<>{children}</>
				) : (
					<Redirect
						to={{
							// TODO
							// @ts-ignore
							pathname: query.get("to") ? query.get("to") : "/",
							state: { from: location }
						}}
					/>
				)
			}
		/>
	)
}
