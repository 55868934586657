import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"

import SignIn from "src/auth/views/SignIn"
import Home from "src/home/views/Home"
import SignUp from "src/auth/views/SignUp"
import PrivateRoute from "src/shared/components/PrivateRoute"
import Verify from "src/auth/views/Verify"
import withAuth from "src/auth/components/withAuth"
import AuthRoute from "src/shared/components/AuthRoute"
import withRooms from "src/rooms/components/withRooms"
import Rooms from "src/rooms/components/Rooms"
import SignOut from "src/auth/views/SignOut"
import withMedia from "./media/components/withMedia"
import withMessages from "./messages/components/withMessages"
import ForgotPassword from "./auth/views/ForgotPassword"
import ResetPassword from "./auth/views/ResetPassword"

const HomeWithRooms = withRooms(() => {
	return <Route exact path="/" children={<Home />} />
})

function AppRouter() {
	return (
		<Router>
			<App />
		</Router>
	)
}

function App() {
	return (
		<Switch>
			<Route exact path="/" children={<HomeWithRooms />} />
			<AuthRoute path="/signin" children={<SignIn />} />
			<AuthRoute path="/signup" children={<SignUp />} />
			<AuthRoute path="/verify" children={<Verify />} />
			<AuthRoute path="/forgot-password" children={<ForgotPassword />} />
			<AuthRoute path="/reset-password" children={<ResetPassword />} />
			<PrivateRoute path="/signout" children={<SignOut />} />
			<PrivateRoute path="/rooms" children={<Rooms />} />
			<Redirect to="/" />
		</Switch>
	)
}

export default withAuth(withMedia(withMessages(AppRouter)))
