import { createTheme, styled } from "@stitches/react";

export const StreamContainer = styled("div", {
  width: "100%",
  height: "100%",
  position: "relative",
});

export const StreamAvatar = styled("div", {
  position: "absolute",
  top: "0",
  left: "0",
  width: 100,
  height: 100,
  color: "white",
  borderRadius: 100,
});

export const StreamVideo = styled("video", {
  width: "100%",
  height: "100%",
  backgroundColor: "$greyDarker",

  variants: {
    flipped: {
      true: {
        transform: "scaleX(-1)",
        objectFit: "cover",
      },
    },
  },
});

export const StreamName = styled("span", {
  left: 0,
  bottom: 0,
  zIndex: 1,
  padding: 20,
  lineHeight: 1,
  position: "absolute",
  textShadow: "0px 0px 8px #000, 0px 0px 16px #000, 0px 0px 32px #000",
});
