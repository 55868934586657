function Screen(props: any) {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5 4a2 2 0 00-2 2v8a2 2 0 002 2h14a2 2 0 002-2V6a2 2 0 00-2-2H5zm5 3l6 3-6 3V7z"
				fill="#fff"
			/>
			<path fill="#fff" d="M11 17h2v3h-2z" />
			<path fill="#fff" d="M8 19h8v2H8z" />
		</svg>
	)
}

export default Screen
