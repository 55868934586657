import { useReducer } from "react"

import { MessagesReducer } from "src/messages/reducers/messages"
import { MessagesContext, initialMessagesState } from "src/messages/contexts/messages"

export default function withMessages(WrappedComponent: any) {
    return (props: any) => {
        const [state, dispatch] = useReducer(MessagesReducer, initialMessagesState)

        return (
            <MessagesContext.Provider value={{ state, dispatch }}>
                <WrappedComponent {...props} />
            </MessagesContext.Provider>
        )
    }
}
