import {
  MessagesActionTypes,
  MessagesStateType,
  MessagesActions,
} from "src/messages/message";

export const MessagesReducer = (
  prevState: MessagesStateType,
  action: MessagesActions
): MessagesStateType => {
  switch (action.type) {
    case MessagesActionTypes.CreatingMessage:
      return {
        ...prevState,
        creatingMessage: action.payload.creatingMessage,
      };
    case MessagesActionTypes.CreatedMessage:
      return {
        ...prevState,
        messages: [
          ...(prevState?.messages || []),
          action.payload.createdMessage,
        ],
        createdMessage: action.payload.createdMessage,
      };
    case MessagesActionTypes.CreateMessageError:
      return {
        ...prevState,
        createMessageError: action.payload.createMessageError,
      };
    case MessagesActionTypes.ReceiveMessages:
      return {
        ...prevState,
        messages: action.payload.messages,
      };
  }
};
