import { Link, useHistory } from "react-router-dom"
import { useEffect, useRef } from "react"

import ErrorMessage from "src/shared/components/ErrorMessage"
import Field from "src/shared/components/Field"
import Form from "src/shared/components/Form"
import useForm from "src/shared/hooks/useForm"
import Button from "src/shared/styles/Button"
import StyledSubmit from "src/shared/styles/Submit"
import { ISignUp } from "src/auth/auth"
import useAuth from "src/auth/hooks/useAuth"
import { delay } from "src/shared/utils/delay"
import useQuery from "src/shared/hooks/useQuery"

export default function SignUpForm() {
	const query = useQuery()
	const history = useHistory()
	const { handleSubmit } = useForm()
	const inputRef = useRef<HTMLInputElement>(null)
	const { signupError, signupUserData, signingUp, signUp } = useAuth()

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		async function navigateToVerify() {
			// Wait for form to animate out
			await delay(300)

			history.push(query.get("to") ? `/verify/?to=${query.get("to")}` : `/verify`)
		}

		// Require caching of signup data, specifically the username to confirm a user
		if (signupUserData) {
			navigateToVerify()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, signupUserData])

	/**
	 * On submit, sign up
	 *
	 * @param data
	 */
	const onSubmit = async (data: ISignUp) => {
		await signUp(data)
	}

	return (
		<Form loading={signingUp} success={Boolean(signupUserData)} handleSubmit={handleSubmit(onSubmit)}>
			<ErrorMessage visible={Boolean(signupError)}>
				<span style={{ flexGrow: 1 }}>{signupError}</span>
			</ErrorMessage>

			<Field ref={inputRef} label="Full name" name="fullName" type="text" tabIndex={2} />
			<Field label="Email" name="email" type="email" tabIndex={3} required />
			<Field label="Password" name="password" type="password" tabIndex={4} required />
			<Field
				type="checkbox"
				name="terms"
				text={
					<span>
						Agree to{" "}
						<a href="https://google.com" target="_blank" rel="noopener noreferrer" tabIndex={6}>
							Terms
						</a>{" "}
						&{" "}
						<a href="https://google.com" target="_blank" rel="noopener noreferrer" tabIndex={7}>
							Privacy Policy
						</a>
					</span>
				}
				tabIndex={5}
				required
			/>

			<StyledSubmit>
				<Link to={query.get("to") ? `/signin/?to=${query.get("to")}` : "/signin"}>Have account?</Link>
				<Button border="gradient" type="submit" disabled={signingUp} tabIndex={8}>
					Sign up
				</Button>
			</StyledSubmit>
		</Form>
	)
}
