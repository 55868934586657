import dayjs from "dayjs";
import React, { useEffect, useRef } from "react";
import { IMessage } from "../message";
import * as StyledMessageList from "../styles/MessageList";
import MessageListItem from "./MessageListItem";

interface IMessageList {
    messages?: Partial<IMessage>[];
}

export default function MessageList({ messages }: IMessageList) {
    const messageListRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (messageListRef.current) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    }, [messages]);

    let prevMessage: Partial<IMessage> | undefined
    let messageComponent: any

    return (
        <StyledMessageList.List ref={messageListRef}>
            {messages &&
                messages.map((message: Partial<IMessage>, index: number) => {
                    if (dayjs(prevMessage?.createdAt).format('DD') !== dayjs(message.createdAt).format('DD')) {
                        messageComponent = (
                            <React.Fragment key={`message-${index}`}>
                                <StyledMessageList.DateSeperator>{dayjs(message.createdAt).format('dddd, D MMM')}</StyledMessageList.DateSeperator>
                                <MessageListItem message={message} />
                            </React.Fragment>
                        )
                    } else {
                        messageComponent = <MessageListItem key={`message-${index}`} message={message} />
                    }

                    prevMessage = message
                    return messageComponent
                })}
        </StyledMessageList.List>
    );
}
