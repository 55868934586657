import React, { useEffect } from "react"
import { useKeys } from "../hooks/useKeys"
import EndCallIcon from "../icons/EndCall"
import Button from "../styles/Button"

interface EndCallProps { }

export default function EndCall(props: EndCallProps) {
	const { keysDown, isPressed } = useKeys()

	useEffect(() => {
		if (isPressed(['AltLeft', 'KeyE'])) {
			endCall()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keysDown])

	async function endCall() {
		window.location.href = '/'
	}

	return (
		<Button onClick={endCall} css={{ backgroundColor: '$red', padding: 12, margin: 5 }}>
			<EndCallIcon />
		</Button>
	)
}
