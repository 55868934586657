import { forwardRef } from "react"
import StyledText from "src/shared/styles/Text"

// TODO ref type

export default forwardRef((props: any, ref: any) => {
  return (
    <StyledText ref={ref} {...props} />
  )
})
