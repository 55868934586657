import { globalCss } from "@stitches/core";

const globalStyles = globalCss({
  "@font-face": [
    {
      fontFamily: "Sofia Pro",
      fontWeight: "500",
      fontDisplay: "swap",
      src: 'url(/assets/fonts/SofiaPro-Medium.woff2) format("woff2"), url(/assets/fonts/SofiaPro-Medium.woff) format("woff")',
    },
    {
      fontFamily: "Sofia Pro Semi",
      fontWeight: "600",
      fontDisplay: "swap",
      src: 'url(/assets/fonts/SofiaPro-SemiBold.woff2) format("woff2"), url(/assets/fonts/SofiaPro-SemiBold.woff) format("woff")',
    },
  ],

  "*, *:before, *:after": {
    boxSizing: "inherit",
  },

  html: {
    overflowX: "hidden",
    boxSizing: "border-box",
  },

  body: {
    margin: 0,
    color: "$white",
    fontFamily: "$sofia",
    backgroundColor: "$greyDark",
    textRendering: "optimizeLegibility",
    "-webkit-font-smoothing": "antialiased",
  },

  a: {
    textDecoration: "none",
    color: "$white",
    position: "relative",
    display: "inline-block",
    transition: "all .3s",

    "&:-webkit-any-link:focus-visible": {
      outline: 0,
      borderRadius: "3px",
      boxShadow:
        "0 0 0 2px rgba(255,255,255,1), 0 0 10px 0 rgba(255,255,255,0.5)",
    },
  },

  svg: { display: "block" },

  "pre, code": { margin: 0 },

  "::selection": {
    color: "$greyDark",
    backgroundColor: "$white",
  },
});

export default globalStyles;
