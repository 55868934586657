import { forwardRef, useEffect } from "react"
import { getInitials } from "src/shared/utils/string"
import { StreamContainer, StreamVideo, StreamName, StreamAvatar } from "../../shared/styles/Stream"

interface IStream {
	stream?: MediaStream
	muted?: boolean
	name?: string
	flipped?: boolean
}

const Stream = forwardRef(({ stream, name, muted, flipped }: IStream, ref: any) => {

	useEffect(() => {
		if (ref && ref.current && stream) {
			ref.current.srcObject = stream
		}
	}, [ref, stream])

	return (
		<StreamContainer>
			{/* {name && <StreamAvatar>{getInitials(name)}</StreamAvatar>} */}
			{name && <StreamName>{name}</StreamName>}
			<StreamVideo ref={ref} autoPlay muted={muted} playsInline flipped={flipped}></StreamVideo>
		</StreamContainer>
	)
})

export default Stream
