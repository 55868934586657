function Record(props: any) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={12} cy={12} r={6} fill="#fff" />
      <circle cx={12} cy={12} r={9.5} stroke="#fff" />
    </svg>
  )
}

export default Record
