import { Role, SignalingClient } from "amazon-kinesis-video-streams-webrtc";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
import KinesisVideo from "aws-sdk/clients/kinesisvideo";
import { useContext, useRef, useState } from "react";
import { MediaContext } from "../contexts/media";
import { MediaActionTypes } from "../media";

function getRandomClientId() {
  return Math.random().toString(36).substring(2).toUpperCase();
}

export default function useKinesisViewer() {
  const { state, dispatch } = useContext(MediaContext);

  const region = process.env.REACT_APP_KINESIS_REGION || "eu-west-2";
  const ChannelName = process.env.REACT_APP_KINESIS_CHANNEL_NAME || "nero";
  const ChannelARN =
    process.env.REACT_APP_KINESIS_CHANNEL_ARN ||
    "arn:aws:kinesisvideo:eu-west-2:650668100444:channel/nero/1627560764041";

  const clientId = getRandomClientId();
  const forceTURN = false;
  const natTraversalDisabled = false;
  const widescreen = false;
  const sendVideo = true;
  const sendAudio = true;
  const useTrickleICE = true;
  const openDataChannel = false;

  const resolution = widescreen
    ? { width: { ideal: 1280 }, height: { ideal: 720 } }
    : { width: { ideal: 640 }, height: { ideal: 480 } };

  const constraints = {
    video: sendVideo ? resolution : false,
    audio: sendAudio,
  };

  const onStatsReport = () => {};

  const kinesisVideoClient = useRef<any>(null);
  const channelARN = useRef<any>(null);
  const endpointsByProtocol = useRef<any>(null);
  const signalingClient = useRef<any>(null);
  const configuration = useRef<any>(null);
  const peerConnection = useRef<any>(null);
  const dataChannel = useRef<any>(null);
  const peerConnectionStatsInterval = useRef<any>(null);

  const localStreamRef = useRef<any>(null);
  const remoteStreamsRef = useRef<any>([]);

  async function init() {
    await newVideoClient();
    await getEndpoints();
    await getICEServers();
    await createSignalingClient();
  }

  /**
   * New Kinesis video client
   */
  async function newVideoClient() {
    const credentials = await Auth.currentUserCredentials();

    kinesisVideoClient.current = new KinesisVideo({
      credentials,
      region: process.env.REACT_APP_KINESIS_REGION,
      endpoint: undefined,
      correctClockSkew: true,
    });

    // Get signaling channel ARN
    const describeSignalingChannelResponse = await kinesisVideoClient.current
      .describeSignalingChannel({
        ChannelName,
      })
      .promise();
    channelARN.current =
      describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    console.log(`[VIEWER] Channel ARN: `, channelARN);
  }

  /**
   * Get signal channel endpoints
   */
  async function getEndpoints() {
    const getSignalingChannelEndpointResponse = await kinesisVideoClient.current
      .getSignalingChannelEndpoint({
        ChannelARN: process.env.REACT_APP_KINESIS_CHANNEL_ARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ["WSS", "HTTPS"],
          Role: Role.VIEWER,
        },
      })
      .promise();

    endpointsByProtocol.current =
      getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
        (endpoints: any, endpoint: any) => {
          endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
          return endpoints;
        },
        {}
      );

    console.log(`[VIEWER] Endpoints: `, endpointsByProtocol);
  }

  /**
   * Get ICE servers
   */
  async function getICEServers() {
    const credentials = await Auth.currentUserCredentials();

    // Get ICE server configuration
    const kinesisVideoSignalingChannelsClient =
      new AWS.KinesisVideoSignalingChannels({
        region,
        endpoint: endpointsByProtocol.current.HTTPS,
        correctClockSkew: true,
        credentials,
      });

    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN,
      })
      .promise();

    const iceServers = [];

    if (!natTraversalDisabled && !forceTURN) {
      iceServers.push({
        urls: `stun:stun.kinesisvideo.${region}.amazonaws.com:443`,
      });
    }

    if (!natTraversalDisabled) {
      getIceServerConfigResponse.IceServerList?.forEach((iceServer) =>
        iceServers.push({
          urls: iceServer.Uris,
          username: iceServer.Username,
          credential: iceServer.Password,
        })
      );
    }

    configuration.current = {
      iceServers,
      iceTransportPolicy: forceTURN ? "relay" : "all",
    };

    console.log(`[VIEWER] ICE servers: `, iceServers);
  }

  /**
   * Create Signaling Client
   */
  async function createSignalingClient() {
    const credentials = await Auth.currentUserCredentials();

    // Create Signaling Client
    signalingClient.current = new SignalingClient({
      region,
      channelARN: channelARN.current,
      channelEndpoint: endpointsByProtocol.current.WSS,
      clientId,
      role: Role.VIEWER,
      credentials,
    });

    peerConnection.current = new RTCPeerConnection(configuration.current);

    if (openDataChannel) {
      dataChannel.current =
        peerConnection.current.createDataChannel("kvsDataChannel");
      peerConnection.current.ondatachannel = (event: any) => {
        event.channel.onmessage = (data: any) => {
          console.log(data);
        }; //onRemoteDataMessage;
      };
    }

    // Poll for connection stats
    peerConnectionStatsInterval.current = setInterval(
      () => peerConnection.current.getStats().then(onStatsReport),
      1000
    );

    signalingClient.current.on("open", async () => {
      console.log("[VIEWER] Connected to signaling service");

      // Get a stream from the webcam, add it to the peer connection, and display it in the local view
      try {
        localStreamRef.current = await navigator.mediaDevices.getUserMedia(
          constraints
        );

        localStreamRef.current
          .getTracks()
          .forEach((track: any) =>
            peerConnection.current.addTrack(track, localStreamRef.current)
          );

        dispatch({
          type: MediaActionTypes.ReceiveLocalStream,
          payload: {
            localStream: localStreamRef.current,
          },
        });
      } catch (e) {
        console.error("[VIEWER] Could not find webcam");
        return;
      }

      // Create an SDP offer to send to the master
      console.log("[VIEWER] Creating SDP offer");
      await peerConnection.current.setLocalDescription(
        await peerConnection.current.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        })
      );

      // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
      if (useTrickleICE) {
        console.log("[VIEWER] Sending SDP offer");
        signalingClient.current.sendSdpOffer(
          peerConnection.current.localDescription
        );
      }
      console.log("[VIEWER] Generating ICE candidates");
    });

    signalingClient.current.on("sdpAnswer", async (answer: any) => {
      // Add the SDP answer to the peer connection
      console.log("[VIEWER] Received SDP answer");
      await peerConnection.current.setRemoteDescription(answer);
    });

    signalingClient.current.on("iceCandidate", (candidate: any) => {
      // Add the ICE candidate received from the MASTER to the peer connection
      console.log("[VIEWER] Received ICE candidate");
      peerConnection.current.addIceCandidate(candidate);
    });

    signalingClient.current.on("close", () => {
      console.log("[VIEWER] Disconnected from signaling channel");
    });

    signalingClient.current.on("error", (error: any) => {
      console.error("[VIEWER] Signaling client error: ", error);
    });

    // Send any ICE candidates to the other peer
    peerConnection.current.addEventListener(
      "icecandidate",
      ({ candidate }: any) => {
        if (candidate) {
          console.log("[VIEWER] Generated ICE candidate");

          // When trickle ICE is enabled, send the ICE candidates as they are generated.
          if (useTrickleICE) {
            console.log("[VIEWER] Sending ICE candidate");
            signalingClient.current.sendIceCandidate(candidate);
          }
        } else {
          console.log("[VIEWER] All ICE candidates have been generated");

          // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
          if (!useTrickleICE) {
            console.log("[VIEWER] Sending SDP offer");
            signalingClient.current.sendSdpOffer(
              peerConnection.current.localDescription
            );
          }
        }
      }
    );

    // As remote tracks are received, add them to the remote view
    peerConnection.current.addEventListener("track", (event: any) => {
      if (
        !remoteStreamsRef.current.find(
          (stream: MediaStream) => stream.id === event.streams[0].id
        )
      ) {
        remoteStreamsRef.current = [
          ...remoteStreamsRef.current,
          event.streams[0],
        ];

        dispatch({
          type: MediaActionTypes.ReceiveRemoteStreams,
          payload: {
            remoteStreams: remoteStreamsRef.current,
          },
        });
      }
    });

    console.log("[VIEWER] Starting viewer connection");
    signalingClient.current.open();
  }

  return {
    ...state,
    init,
  };
}
