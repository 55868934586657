import { useEffect, useState } from "react";

export function useKeys() {
  const [keysDown, setKeysDown] = useState<string[]>([]);

  useEffect(() => {
    window.addEventListener("keydown", keyDown);
    window.addEventListener("keyup", keyUp);

    return () => {
      window.removeEventListener("keydown", keyDown);
      window.removeEventListener("keyup", keyUp);
    };
  }, []);

  /**
   * Key down
   *
   * @param e
   */
  function keyDown(e: KeyboardEvent) {
    setKeysDown((keysDown) => {
      if (!keysDown.includes(e.code)) {
        return [...keysDown, e.code];
      } else {
        return keysDown;
      }
    });
  }

  /**
   * Key up
   *
   * @param e
   */
  function keyUp(e: KeyboardEvent) {
    setKeysDown((keysDown) => {
      if (keysDown.includes(e.code)) {
        return keysDown.filter((code) => code !== e.code);
      } else {
        return keysDown;
      }
    });
  }

  /**
   * Is pressed
   *
   * @param code
   * @returns
   */
  function isPressed(code: string | string[]) {
    if (!keysDown.length) return false;

    return Array.isArray(code) && keysDown.length > 1
      ? keysDown.filter((keyDown) => !code.includes(keyDown)).length === 0
      : keysDown.includes(code as string);
  }

  return {
    keysDown,
    isPressed,
  };
}
