import { useRef } from "react";

export default function useMediaRecorder() {
  const mediaRecorder = useRef<any>();
  const recordedBlobs = useRef<any>();

  async function handleDataAvailable(event: any) {
    console.log("handleDataAvailable", event);

    if (event.data && event.data.size > 0) {
      recordedBlobs.current.push(event.data);
    }
  }

  async function record(stream: MediaStream) {
    recordedBlobs.current = [];
    const mimeType = "video/webm";
    const options = { mimeType };

    try {
      mediaRecorder.current = new (window as any).MediaRecorder(
        stream,
        options
      );
    } catch (e) {
      console.error("Exception while creating MediaRecorder:", e);
      return;
    }

    mediaRecorder.current.onstop = (event: any) => {
      console.log("Recorder stopped: ", event);
      console.log("Recorded Blobs: ", recordedBlobs.current);

      download(recordedBlobs.current);
    };

    mediaRecorder.current.ondataavailable = handleDataAvailable;
    mediaRecorder.current.start();

    console.log("MediaRecorder started", mediaRecorder.current);
  }

  async function stop() {
    mediaRecorder.current?.stop();
  }

  async function download(recordedBlobs: any) {
    const blob = new Blob(recordedBlobs, {
      type: "video/webm",
    });

    const url: any = URL.createObjectURL(blob);
    const a: any = document.createElement("a");
    a.style = "display: none";
    a.href = url;
    a.download = "test.webm";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  }

  return {
    record,
    stop,
  };
}
