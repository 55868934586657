import SettingsForm from "../components/forms/Settings";
import * as S from '../../shared/styles/Modal'
import { useKeys } from "src/shared/hooks/useKeys";
import { useEffect, useRef } from "react";

interface ISettings {
	onClose?: () => void
}

export default function Settings({ onClose }: ISettings) {
	const { isPressed, keysDown } = useKeys()

	const containerRef = useRef<any>()
	const boxRef = useRef<any>()

	useEffect(() => {
		if (isPressed('Escape')) {
			onClose && onClose()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keysDown])

	function goBack(e: any) {
		if (!boxRef.current.contains(e.target)) {
			onClose && onClose()
		}
	}

	return (
		<S.Container ref={containerRef} onClick={goBack}>
			<S.Box ref={boxRef}>
				<SettingsForm />
			</S.Box>
		</S.Container>
	);
}
