import useAuth from "src/auth/hooks/useAuth"
import RoomNew from "src/rooms/views/RoomNew"
import ReserveUsername from "./ReserveUsername"

export default function Home() {
	const { currentUser } = useAuth()

	if (currentUser) {
		return (
			<RoomNew />
		)
	} else {
		return <ReserveUsername />
	}
}
