import { API } from "aws-amplify";
import { useContext } from "react"
import * as queries from 'src/graphql/queries';
import * as mutations from 'src/graphql/mutations';

import { UsersContext } from "../contexts/users"
import { IUser, UsersActionTypes } from "../user";
import { AuthActionTypes } from "src/auth/auth";
import { AuthContext } from "src/auth/contexts/auth";

function useUsers() {
  const { state, dispatch } = useContext(UsersContext)
  const { dispatch: authDispatch } = useContext(AuthContext)

  /**
   * Create a user
   * 
   * @param data 
   */
  async function createUser(data: Partial<IUser>) {
    const response: any = await API.graphql({ query: mutations.createUser, variables: { input: data } });
    authDispatch({ type: AuthActionTypes.ReceiveCurrentUser, payload: { currentUser: response.data.createUser } })
  }

  /**
   * Update a user
   * 
   * @param data 
   */
  async function updateUser(data: Partial<IUser>) {
    const response: any = await API.graphql({ query: mutations.updateUser, variables: { input: data } });
    authDispatch({ type: AuthActionTypes.ReceiveCurrentUser, payload: { currentUser: response.data.updateUser } })
  }

  /**
   * Get users available to chat
   */
  async function getUsersAvailableToChat() {
    const response: any = await API.graphql({ query: queries.listUsers, variables: { limit: 5 } });
    dispatch({ type: UsersActionTypes.ReceiveAvailableToChatUsers, payload: { usersAvailableToChat: response.data.listUsers.items } })
  }

  /**
   * Get user
   */
  async function getUser(id: string) {
    const response: any = await API.graphql({ query: queries.getUser, variables: { id } });
    return response.data.getUser
  }

  return {
    ...state,
    createUser,
    updateUser,
    getUsersAvailableToChat,
    getUser
  }
}

export default useUsers