import { styled } from "@stitches/react";

export const Container = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  cursor: "pointer",
  borderRadius: "6px",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "$greyDarker",
});

export const Inner = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  "& span": {
    bottom: -2,
    lineHeight: 1,
    position: "relative",
  },
});
