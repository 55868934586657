import styled from "./theme";

const StyledCheckbox = styled('div', {
  position: 'relative'
});

export const Input = styled('input', {
  margin: 0,
  width: '20px',
  height: '20px',
  appearance: 'none',
  borderRadius: '6px',
  position: 'relative',
  background: '#0D0D0D',
  color: '#FFFFFF',
  display: 'block',
  cursor: 'pointer',
  transition: 'all .2s',

  '&:hover': {
    boxShadow: '0 0 0 2px rgba(255,255,255,0.1)'
  },

  '&:focus': {
    outline: 0,
    boxShadow: '0 0 0 2px rgba(255,255,255,1), 0 0 10px 0 rgba(255,255,255,0.5)'
  },

  '&:active': {
    transform: 'scale(0.8)',
    boxShadow: '0 0 0 2px rgba(255,255,255,0.5), 0 0 10px 0 rgba(255,255,255,0.25)'
  },
})

export default StyledCheckbox