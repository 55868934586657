import { useEffect, useState } from "react"
import { useUserMedia } from "src/media/hooks/useUserMedia"
import { useKeys } from "../../shared/hooks/useKeys"
import VideoCameraIcon from "../../shared/icons/VideoCamera"
import Button from "../../shared/styles/Button"

interface VideoCameraProps { }

export default function VideoCamera(props: VideoCameraProps) {
	const { localStream } = useUserMedia()
	const [cameraActive, setCameraActive] = useState<boolean>(true)
	const { keysDown, isPressed } = useKeys()

	useEffect(() => {
		if (isPressed(['AltLeft', 'KeyV'])) {
			toggleCamera()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keysDown])

	async function toggleCamera() {
		setCameraActive(!cameraActive)

		const tracks: any = localStream?.getVideoTracks()

		// TODO improve selection
		if (tracks) {
			tracks[0].enabled = !cameraActive
		}
	}

	return (
		<Button onClick={toggleCamera} css={{ padding: 12, margin: 5, opacity: cameraActive ? 1 : 0.5 }}>
			<VideoCameraIcon />
		</Button>
	)
}
