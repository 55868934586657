import { forwardRef } from "react"
import StyledTextarea from "src/shared/components/Textarea"

// TODO ref type

export default forwardRef((props: any, ref: any) => {
  return (
    <StyledTextarea ref={ref} {...props} />
  )
})
