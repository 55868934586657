import * as S from 'src/shared/styles/Shortcuts'
import { useKeys } from '../../shared/hooks/useKeys'

export default function KeyboardShortcuts() {
    const { isPressed } = useKeys()

    return (
        <div>
            <h3>Keyboard shortcuts</h3>
            <S.Container>
                <S.Shortcut>
                    <S.Label active={isPressed(['AltLeft', 'KeyE'])}>End call</S.Label>
                    <S.Keys><S.Key pressed={isPressed('AltLeft')}>⌥ option</S.Key><S.Key pressed={isPressed('KeyE')}>E</S.Key></S.Keys>
                </S.Shortcut>
                <S.Shortcut>
                    <S.Label active={isPressed(['AltLeft', 'KeyF'])}>Full screen</S.Label>
                    <S.Keys><S.Key pressed={isPressed('AltLeft')}>⌥ option</S.Key><S.Key pressed={isPressed('KeyF')}>F</S.Key></S.Keys>
                </S.Shortcut>
                <S.Shortcut>
                    <S.Label active={isPressed(['AltLeft', 'KeyS'])}>Share screen</S.Label>
                    <S.Keys><S.Key pressed={isPressed('AltLeft')}>⌥ option</S.Key><S.Key pressed={isPressed('KeyS')}>S</S.Key></S.Keys>
                </S.Shortcut>
                <S.Shortcut>
                    <S.Label active={isPressed(['AltLeft', 'KeyP'])}>Settings</S.Label>
                    <S.Keys><S.Key pressed={isPressed('AltLeft')}>⌥ option</S.Key><S.Key pressed={isPressed('KeyP')}>P</S.Key></S.Keys>
                </S.Shortcut>
                <S.Shortcut>
                    <S.Label active={isPressed(['AltLeft', 'KeyM'])}>Toggle mute</S.Label>
                    <S.Keys><S.Key pressed={isPressed('AltLeft')}>⌥ option</S.Key><S.Key pressed={isPressed('KeyM')}>M</S.Key></S.Keys>
                </S.Shortcut>
                <S.Shortcut>
                    <S.Label active={isPressed(['AltLeft', 'KeyV'])}>Toggle video</S.Label>
                    <S.Keys><S.Key pressed={isPressed('AltLeft')}>⌥ option</S.Key><S.Key pressed={isPressed('KeyV')}>V</S.Key></S.Keys>
                </S.Shortcut>
                <S.Shortcut>
                    <S.Label active={isPressed(['AltLeft', 'KeyC'])}>Toggle Subtitles</S.Label>
                    <S.Keys><S.Key pressed={isPressed('AltLeft')}>⌥ option</S.Key><S.Key pressed={isPressed('KeyV')}>C</S.Key></S.Keys>
                </S.Shortcut>
                <S.Shortcut>
                    <S.Label active={isPressed(['AltLeft', 'KeyV'])}>Record</S.Label>
                    <S.Keys><S.Key pressed={isPressed('AltLeft')}>⌥ option</S.Key><S.Key pressed={isPressed('KeyV')}>R</S.Key></S.Keys>
                </S.Shortcut>
            </S.Container>
        </div>
    )
}