import { useState } from "react";

interface IAudioVisualizer {
    stream?: MediaStream;
}

export default function AudioVisualizer({ stream }: IAudioVisualizer) {
    const [frequencyData] = useState<Float32Array>(new Float32Array(32))

    // const interval = useRef<any>()

    // const audioContext = useRef<any>()
    // const audioSource = useRef<any>()
    // const analyser = useRef<any>()

    // useEffect(() => {
    //     if (stream && !interval.current) {
    //         audioContext.current = new AudioContext();
    //         audioSource.current = audioContext.current.createMediaStreamSource(stream)
    //         analyser.current = audioSource.current.context.createAnalyser()

    //         audioSource.current.connect(analyser.current);
    //         analyser.current.connect(audioContext.current.destination)
    //         analyser.current.fftSize = 32;
    //     }
    // }, [stream, frequencyData]);

    // useInterval(() => {
    //     if (analyser.current) {
    //         analyser.current.getFloatFrequencyData(frequencyData)
    //         setFrequencyData(() => new Float32Array(frequencyData))
    //     }
    // }, 100)

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div
                style={{
                    margin: "0 2px",
                    width: 4,
                    height: Math.abs(Number.NEGATIVE_INFINITY === frequencyData[0] ? 4 : frequencyData[0]) / 6,
                    borderRadius: 4,
                    backgroundColor: "white",
                }}
            ></div>
            <div
                style={{
                    margin: "0 2px",
                    width: 4,
                    height: Math.abs(Number.NEGATIVE_INFINITY === frequencyData[2] ? 4 : frequencyData[2]) / 6,
                    borderRadius: 4,
                    backgroundColor: "white",
                }}
            ></div>
            <div
                style={{
                    margin: "0 2px",
                    width: 4,
                    height: Math.abs(Number.NEGATIVE_INFINITY === frequencyData[4] ? 4 : frequencyData[4]) / 6,
                    borderRadius: 4,
                    backgroundColor: "white",
                }}
            ></div>
        </div>
    );
}
