/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      owner
      email
      phone
      username
      fullName
      profilePicture
      presence
      rooms {
        items {
          id
          owner
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          room
          owner
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      owner
      email
      phone
      username
      fullName
      profilePicture
      presence
      rooms {
        items {
          id
          owner
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          room
          owner
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      owner
      email
      phone
      username
      fullName
      profilePicture
      presence
      rooms {
        items {
          id
          owner
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          room
          owner
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRoom = /* GraphQL */ `
  subscription OnCreateRoom {
    onCreateRoom {
      id
      owner
      name
      user {
        id
        owner
        email
        phone
        username
        fullName
        profilePicture
        presence
        rooms {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          room
          owner
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRoom = /* GraphQL */ `
  subscription OnUpdateRoom {
    onUpdateRoom {
      id
      owner
      name
      user {
        id
        owner
        email
        phone
        username
        fullName
        profilePicture
        presence
        rooms {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          room
          owner
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRoom = /* GraphQL */ `
  subscription OnDeleteRoom {
    onDeleteRoom {
      id
      owner
      name
      user {
        id
        owner
        email
        phone
        username
        fullName
        profilePicture
        presence
        rooms {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          room
          owner
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      room
      owner
      user {
        id
        owner
        email
        phone
        username
        fullName
        profilePicture
        presence
        rooms {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      text
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      room
      owner
      user {
        id
        owner
        email
        phone
        username
        fullName
        profilePicture
        presence
        rooms {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      text
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      room
      owner
      user {
        id
        owner
        email
        phone
        username
        fullName
        profilePicture
        presence
        rooms {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      text
      createdAt
      updatedAt
    }
  }
`;
