/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      owner
      email
      phone
      username
      fullName
      profilePicture
      presence
      rooms {
        items {
          id
          owner
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          room
          owner
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      owner
      email
      phone
      username
      fullName
      profilePicture
      presence
      rooms {
        items {
          id
          owner
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          room
          owner
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      owner
      email
      phone
      username
      fullName
      profilePicture
      presence
      rooms {
        items {
          id
          owner
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          room
          owner
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRoom = /* GraphQL */ `
  mutation CreateRoom(
    $input: CreateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    createRoom(input: $input, condition: $condition) {
      id
      owner
      name
      user {
        id
        owner
        email
        phone
        username
        fullName
        profilePicture
        presence
        rooms {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          room
          owner
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom(
    $input: UpdateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    updateRoom(input: $input, condition: $condition) {
      id
      owner
      name
      user {
        id
        owner
        email
        phone
        username
        fullName
        profilePicture
        presence
        rooms {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          room
          owner
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRoom = /* GraphQL */ `
  mutation DeleteRoom(
    $input: DeleteRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    deleteRoom(input: $input, condition: $condition) {
      id
      owner
      name
      user {
        id
        owner
        email
        phone
        username
        fullName
        profilePicture
        presence
        rooms {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          room
          owner
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      room
      owner
      user {
        id
        owner
        email
        phone
        username
        fullName
        profilePicture
        presence
        rooms {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      text
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      room
      owner
      user {
        id
        owner
        email
        phone
        username
        fullName
        profilePicture
        presence
        rooms {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      text
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      room
      owner
      user {
        id
        owner
        email
        phone
        username
        fullName
        profilePicture
        presence
        rooms {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      text
      createdAt
      updatedAt
    }
  }
`;
