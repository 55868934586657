import StyledCallActions from "../styles/CallActions"
import Microphone from "../../media/components/Microphone"
import VideoCamera from "../../media/components/VideoCamera"
import Screen from "../../media/components/Screen"
import EndCall from "./EndCall"
import { IRoom } from "src/rooms/room"
import Settings from "./Settings"
import FullScreen from "./FullScreen"
import Record from "src/media/components/Record"
import Subtitles from "src/media/components/Subtitles"

interface CallActionsProps {
    room?: Partial<IRoom>
}

export default function CallActions(props: CallActionsProps) {
    return (
        <StyledCallActions {...props}>
            <div>
            </div>
            <div>
                <Record />
                <Subtitles />
                <FullScreen />
                <Settings />
                <Microphone />
                <VideoCamera />
                <Screen />
                <EndCall />
            </div>
            <div>
            </div>
        </StyledCallActions>
    )
}
