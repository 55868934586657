import { ActionMap } from "src/shared/shared";

export type MediaStateType = {
  localStream?: MediaStream;
  localScreenStream?: MediaStream;
  remoteStreams?: MediaStream[];
  devices?: MediaDeviceInfo[];
  permissions?: Permissions;
  constraints?: MediaStreamConstraints;
  updatingMediaPreferences?: boolean;
  updatingMediaPreferencesError?: string;
};

export enum MediaActionTypes {
  ReceiveDevices = "RECEIVE_DEVICES",
  ReceivePermissions = "RECEIVE_PERMISSIONS",
  ReceiveConstraints = "RECEIVE_CONSTRAINTS",
  ReceiveLocalStream = "RECEIVE_LOCAL_STREAM",
  ReceiveLocalScreenStream = "RECEIVE_LOCAL_SCREEN_STREAM",
  ReceiveRemoteStreams = "RECEIVE_REMOTE_STREAM",
}

type MediaPayload = {
  [MediaActionTypes.ReceiveDevices]: {
    devices?: MediaDeviceInfo[];
  };
  [MediaActionTypes.ReceivePermissions]: {
    permissions?: Permissions;
  };
  [MediaActionTypes.ReceiveConstraints]: {
    constraints?: MediaStreamConstraints;
  };
  [MediaActionTypes.ReceiveLocalStream]: {
    localStream?: MediaStream;
  };
  [MediaActionTypes.ReceiveLocalScreenStream]: {
    localScreenStream?: MediaStream;
  };
  [MediaActionTypes.ReceiveRemoteStreams]: {
    remoteStreams?: MediaStream[];
  };
};

export type MediaActions =
  ActionMap<MediaPayload>[keyof ActionMap<MediaPayload>];
