import StyledErrorMessage from "../styles/ErrorMessage"
import ErrorIcon from "../icons/Error"

interface IErrorMessage extends React.ComponentPropsWithoutRef<"div"> {
	visible?: boolean
}

export default function ErrorMessage({ visible, children }: IErrorMessage) {
	return (
		<StyledErrorMessage visible={visible}>
			<ErrorIcon style={{ alignSelf: "flex-start", flexShrink: 0, marginRight: '8px' }} />
			{children}
		</StyledErrorMessage>
	)
}
