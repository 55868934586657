import { useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "src/graphql/mutations";
import * as queries from "src/graphql/queries";
import * as subscriptions from "src/graphql/subscriptions";
import { MessagesContext } from "../contexts/messages";
import { IMessage, MessagesActionTypes } from "../message";
import { AuthContext } from "src/auth/contexts/auth";

function useMessages() {
  const { state, dispatch } = useContext(MessagesContext);
  const { state: authState } = useContext(AuthContext);

  /**
   * Add helper fields
   */
  function addHelperFields(messages: Partial<IMessage>[]): Partial<IMessage>[] {
    return messages?.map((message: Partial<IMessage>) => {
      if (authState?.currentUser.id === message.owner) {
        message.isCurrentUser = true;
      }

      return message;
    });
  }

  /**
   * Get messages
   */
  async function getMessages(room?: string) {
    let variables;
    if (room) {
      variables = { filter: { room: { eq: room } } };
    }

    const response: any = await API.graphql({
      query: queries.listMessages,
      variables,
    });

    const messages = addHelperFields(response.data.listMessages.items);

    dispatch({
      type: MessagesActionTypes.ReceiveMessages,
      payload: { messages },
    });
  }

  /**
   * Subscribe to messages
   */
  async function subscribeToMessages() {
    const subscription = (
      API.graphql(graphqlOperation(subscriptions.onCreateMessage)) as any
    ).subscribe({
      next: ({ value }: any) => {
        dispatch({
          type: MessagesActionTypes.CreatedMessage,
          payload: {
            createdMessage: addHelperFields([value.data.onCreateMessage])[0],
          },
        });
      },
      error: (error: any) => console.warn(error),
    });

    return subscription;
  }

  /**
   * Create message
   *
   * @param data
   */
  async function createMessage(data: Partial<IMessage>) {
    dispatch({
      type: MessagesActionTypes.CreatingMessage,
      payload: { creatingMessage: true },
    });

    try {
      await API.graphql({
        query: mutations.createMessage,
        variables: { input: data },
      });
    } catch (error) {
      console.log("Error signing in", error);
    }

    dispatch({
      type: MessagesActionTypes.CreatingMessage,
      payload: { creatingMessage: false },
    });
  }

  return {
    ...state,
    getMessages,
    createMessage,
    subscribeToMessages,
  };
}

export default useMessages;
