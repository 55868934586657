function FullScreen(props: any) {
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M21 11V3h-8l3 3-3 3 2 2 3-3 3 3zM3 13v8h8l-3-3 3-3-2-2-3 3-3-3z"
                fill="#fff"
            />
        </svg>
    )
}

export default FullScreen
