import styled from "./theme";

export const Container = styled("div", {
  marginBottom: "$2",
});

export const Field = styled("div", {
  width: "100%",
  background: "rgba(23,23,23,0.8)",
  borderRadius: "6px",
  display: "inline-block",
  backdropFilter: "blur(6px)",

  "&:last-child": {
    margin: 0,
  },

  variants: {
    type: {
      text: {},
      email: {},
      password: {},
      image: {},
      select: {},
      textarea: {},
      checkbox: {
        display: "flex",
        paddingLeft: "$1",
        alignItems: "center",
        borderRadius: "6px",
        flexDirection: "row-reverse",
        justifyContent: "flex-end",
      },
    },
  },
});

export const Label = styled("div", {
  display: "flex",
  justifyContent: "space-between",
});

export const LabelAction = styled("div", {
  padding: "$1",
  textTransform: "uppercase",
  fontSize: "10px",
  color: "$greyLight",
  letterSpacing: "0.2em",
  display: "inline-block",
  marginLeft: "auto",
});

export const Help = styled("div", {
  fontSize: "$2",
  marginTop: "$1",
  textAlign: "right",

  a: {
    color: "$grey",
  },
});
