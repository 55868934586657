import { Auth } from "aws-amplify";
import { useContext } from "react";
import { AuthActionTypes, ISignIn, ISignUp, IVerify } from "src/auth/auth";
import useUsers from "src/users/hooks/useUsers";
import { AuthContext } from "../contexts/auth";

export function useAuth() {
  const { state, dispatch } = useContext(AuthContext);
  const { createUser, getUser } = useUsers();

  /**
   * Get current user
   *
   * @returns
   */
  async function getCurrentUser() {
    try {
      const { username }: any = await Auth.currentAuthenticatedUser();

      if (username) {
        const user = await getUser(username);

        if (user) {
          dispatch({
            type: AuthActionTypes.ReceiveCurrentUser,
            payload: { currentUser: user },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Sign in
   *
   * @param data
   */
  async function signIn(data: ISignIn) {
    dispatch({ type: AuthActionTypes.SigningIn, payload: { signingIn: true } });

    try {
      await Auth.signIn(data.email, data.password);

      dispatch({ type: AuthActionTypes.SignedIn, payload: { signedIn: true } });
    } catch (error: any) {
      console.log("Error signing in", error);

      if (error.name === "UserNotConfirmedException") {
        dispatch({
          type: AuthActionTypes.SignedIn,
          payload: { signedIn: true },
        });

        resendConfirmationCode(data.email);
      } else {
        dispatch({
          type: AuthActionTypes.SigninError,
          payload: { signinError: error.message },
        });
      }
    }

    dispatch({
      type: AuthActionTypes.SigningIn,
      payload: { signingIn: false },
    });
  }

  /**
   * Sign up
   *
   * @param data
   */
  async function signUp(data: ISignUp) {
    dispatch({ type: AuthActionTypes.SigningUp, payload: { signingUp: true } });

    try {
      const authData = {
        username: data.email,
        password: data.password,
        attributes: { email: data.email },
      };

      const { userSub } = await Auth.signUp(authData);

      dispatch({
        type: AuthActionTypes.SignupUserData,
        payload: {
          signupUserData: {
            ...data,
            id: userSub,
          },
        },
      });
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: AuthActionTypes.SignupError,
        payload: { signupError: error.message },
      });
    }

    dispatch({
      type: AuthActionTypes.SigningUp,
      payload: { signingUp: false },
    });
  }

  /**
   * Re-send confirmation code
   *
   * @param data
   */
  async function resendConfirmationCode(username: string) {
    try {
      await Auth.resendSignUp(username);
    } catch (error) {
      console.log("Error sending confirmation code:", error);
    }
  }

  /**
   * Send reset password email
   *
   * @param email
   */
  async function sendResetPasswordEmail(email: string) {
    dispatch({
      type: AuthActionTypes.SendingPasswordResetEmail,
      payload: { sendingPasswordResetEmail: true },
    });

    try {
      await Auth.forgotPassword(email);

      dispatch({
        type: AuthActionTypes.ResetPasswordEmailSent,
        payload: { resetPasswordEmailSent: true },
      });
    } catch (error: any) {
      console.log("Error sending reset password email:", error);

      dispatch({
        type: AuthActionTypes.ResetPasswordEmailError,
        payload: { resetPasswordEmailError: error.message },
      });
    }

    dispatch({
      type: AuthActionTypes.SendingPasswordResetEmail,
      payload: { sendingPasswordResetEmail: false },
    });
  }

  /**
   * Reset Password
   *
   * @param email
   */
  async function resetPassword(
    code: string,
    username: string,
    password: string,
    confirmPassword: string
  ) {
    dispatch({
      type: AuthActionTypes.ResettingPassword,
      payload: { resettingPassword: true },
    });

    if (password !== confirmPassword) {
      dispatch({
        type: AuthActionTypes.SignoutError,
        payload: { signoutError: "Passwords do not match" },
      });
    } else {
      await Auth.forgotPasswordSubmit(username, code, password);

      dispatch({
        type: AuthActionTypes.PasswordReset,
        payload: { passwordReset: true },
      });
    }

    dispatch({
      type: AuthActionTypes.ResettingPassword,
      payload: { resettingPassword: false },
    });
  }

  /**
   * Sign out
   */
  async function signOut() {
    dispatch({
      type: AuthActionTypes.SigningOut,
      payload: { signingOut: true },
    });

    try {
      await Auth.signOut();
      dispatch({
        type: AuthActionTypes.SignedOut,
        payload: { signedOut: true },
      });
    } catch (error: any) {
      console.log("Error signing out:", error);

      dispatch({
        type: AuthActionTypes.SignoutError,
        payload: { signoutError: error.message },
      });
    }

    dispatch({
      type: AuthActionTypes.SigningOut,
      payload: { signingOut: false },
    });
  }

  /**
   * Verify
   *
   * @param data
   */
  async function verify(data: IVerify) {
    dispatch({ type: AuthActionTypes.Verifying, payload: { verifying: true } });

    try {
      await Auth.confirmSignUp(data.signupUserData.email, data.code);
      dispatch({ type: AuthActionTypes.Verified, payload: { verified: true } });

      await signIn({
        email: data.signupUserData.email,
        password: data.signupUserData.password,
      });

      // TODO is duplicating and deleting the best way to go here?
      const userData: any = data.signupUserData;
      delete userData.password;
      delete userData.terms;

      await createUser(userData);
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: AuthActionTypes.VerifyError,
        payload: { verifyError: error.message },
      });
    }

    dispatch({
      type: AuthActionTypes.Verifying,
      payload: { verifying: false },
    });
  }

  return {
    ...state,
    getCurrentUser,
    signUp,
    signIn,
    signOut,
    verify,
    resendConfirmationCode,
    sendResetPasswordEmail,
    resetPassword,
  };
}

export default useAuth;
