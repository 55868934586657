import { useReducer } from "react"

import { MediaReducer } from "src/media/reducers/media"
import { MediaContext, initialMediaState } from "src/media/contexts/media"

export default function withMedia(WrappedComponent: any) {
	return (props: any) => {
		const [state, dispatch] = useReducer(MediaReducer, initialMediaState)

		return (
			<MediaContext.Provider value={{ state, dispatch }}>
				<WrappedComponent {...props} />
			</MediaContext.Provider>
		)
	}
}
