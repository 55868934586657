import React, { createContext } from "react"

import { AuthStateType, AuthActions } from "src/auth/auth"

export const initialAuthState: AuthStateType = {
  currentUser: null
}

export const AuthContext = createContext<{
  state: AuthStateType
  dispatch: React.Dispatch<AuthActions>
}>({
  state: initialAuthState,
  dispatch: () => null
})
