import React, { useEffect, useState } from "react"
import { useUserMedia } from "src/media/hooks/useUserMedia"
import { useKeys } from "../../shared/hooks/useKeys"
import MicrophoneIcon from "../../shared/icons/Microphone"
import Button from "../../shared/styles/Button"

interface MicrophoneProps { }

export default function Microphone(props: MicrophoneProps) {
	const { localStream } = useUserMedia()
	const [microphoneActive, setMicrophoneActive] = useState<boolean>(true)
	const { keysDown, isPressed } = useKeys()

	useEffect(() => {
		if (isPressed(['AltLeft', 'KeyM'])) {
			toggleMicrophone()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keysDown])

	async function toggleMicrophone() {
		setMicrophoneActive(!microphoneActive)

		const tracks: any = localStream?.getTracks()

		// TODO improve selection
		if (tracks) {
			tracks[0].enabled = false
		}
	}

	return (
		<Button onClick={toggleMicrophone} css={{ padding: 12, margin: 5, opacity: microphoneActive ? 1 : 0.5 }}>
			<MicrophoneIcon />
		</Button>
	)
}
