import { RoomsActionTypes, RoomsStateType, RoomsActions } from "src/rooms/room"

export const RoomsReducer = (prevState: RoomsStateType, action: RoomsActions): RoomsStateType => {
  switch (action.type) {
    case RoomsActionTypes.CreatingRoom:
      return {
        ...prevState,
        creatingRoom: action.payload.creatingRoom
      }
    case RoomsActionTypes.CreatedRoom:
      return {
        ...prevState,
        createdRoom: action.payload.createdRoom
      }
    case RoomsActionTypes.CreateRoomError:
      return {
        ...prevState,
        createRoomError: action.payload.createRoomError
      }
    case RoomsActionTypes.ReceiveRooms:
      return {
        ...prevState,
        rooms: action.payload.rooms
      }
    case RoomsActionTypes.ReceiveCurrentRoom:
      return {
        ...prevState,
        currentRoom: action.payload.currentRoom
      }
  }
}
