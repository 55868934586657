import { IMessage } from "src/messages/message"
import Field from "src/shared/components/Field"
import Form from "src/shared/components/Form"
import useForm from "src/shared/hooks/useForm"
import useMessage from "src/messages/hooks/useMessage"
import useAuth from "src/auth/hooks/useAuth"
import StyledMessageNew from "src/messages/styles/MessageNew"

interface IMessageNew {
    room?: string
}

export default function MessageNew({ room }: IMessageNew) {
    const { handleSubmit } = useForm()
    const { currentUser } = useAuth()
    const { creatingMessage, createMessage } = useMessage()

    const onSubmit = async (message: IMessage) => {
        if (room) {
            message.room = room
        }

        message.owner = currentUser.id

        await createMessage(message)
    }

    return (
        <StyledMessageNew>
            <Form loading={creatingMessage} handleSubmit={handleSubmit(onSubmit)}>
                <Field placeholder="Aa" name="text" type="text" />
            </Form>
        </StyledMessageNew>
    )
}
