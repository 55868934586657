import { useEffect, useState } from "react"
import { useKeys } from "../../shared/hooks/useKeys"
import SubtitlesIcon from "../../shared/icons/Subtitles"
import Button from "../../shared/styles/Button"

interface SubtitlesProps { }

export default function Subtitles(props: SubtitlesProps) {
  const [subtitlesActive, setSubtitlesActive] = useState<boolean>(true)
  const { keysDown, isPressed } = useKeys()

  useEffect(() => {
    if (isPressed(['AltLeft', 'KeyC'])) {
      toggleSubtitles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysDown])

  async function toggleSubtitles() {
    setSubtitlesActive(!subtitlesActive)

    console.log('subtitles')
  }

  return (
    <Button onClick={toggleSubtitles} css={{ padding: 12, margin: 5, opacity: subtitlesActive ? 1 : 0.5 }}>
      <SubtitlesIcon />
    </Button>
  )
}
