import React, { createContext } from "react";

import { MediaStateType, MediaActions } from "src/media/media";

export const initialMediaState: MediaStateType = {
  devices: [],
  remoteStreams: [],
  updatingMediaPreferences: false,
};

export const MediaContext = createContext<{
  state: MediaStateType;
  dispatch: React.Dispatch<MediaActions>;
}>({
  state: initialMediaState,
  dispatch: () => null,
});
