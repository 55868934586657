function VideoCamera(props: any) {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect x={2} y={5} width={13} height={14} rx={3} fill="#fff" />
			<path
				d="M17.416 9.3l3-2.16A1 1 0 0122 7.953v8.096a1 1 0 01-1.584.811l-3-2.16a1 1 0 01-.416-.811v-3.776a1 1 0 01.416-.811z"
				fill="#fff"
			/>
		</svg>
	)
}

export default VideoCamera
