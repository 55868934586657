/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      email
      phone
      username
      fullName
      profilePicture
      presence
      rooms {
        items {
          id
          owner
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          room
          owner
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        email
        phone
        username
        fullName
        profilePicture
        presence
        rooms {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRoom = /* GraphQL */ `
  query GetRoom($id: ID!) {
    getRoom(id: $id) {
      id
      owner
      name
      user {
        id
        owner
        email
        phone
        username
        fullName
        profilePicture
        presence
        rooms {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          room
          owner
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms(
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        user {
          id
          owner
          email
          phone
          username
          fullName
          profilePicture
          presence
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      room
      owner
      user {
        id
        owner
        email
        phone
        username
        fullName
        profilePicture
        presence
        rooms {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      text
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        room
        owner
        user {
          id
          owner
          email
          phone
          username
          fullName
          profilePicture
          presence
          createdAt
          updatedAt
        }
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
