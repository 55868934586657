import styled from "./theme";

export const Container = styled("div", {});

export const Shortcut = styled("div", {
  display: "flex",
  padding: "10px 0",
  alignItems: "center",
  justifyContent: "space-between",

  "& + &": {
    borderTop: "1px $greyDarker solid",
  },
});

export const Label = styled("span", {
  color: "$grey",

  variants: {
    active: {
      true: {
        color: "$white",
      },
    },
  },
});

export const Keys = styled("div", {
  top: -1,
  fontSize: "$2",
  position: "relative",
});

export const Key = styled("div", {
  minWidth: 32,
  marginLeft: 8,
  color: "$grey",
  borderRadius: "6px",
  alignItems: "center",
  padding: "7px 10px 4px",
  display: "inline-flex",
  justifyContent: "center",
  boxShadow: "0 0 0 1px #0D0D0D, 0 2px 0 1px #0D0D0D",

  variants: {
    pressed: {
      true: {
        bottom: -1,
        position: "relative",
        backgroundColor: "$greyDarker",
        boxShadow: "0 0 0 1px #0D0D0D",
      },
    },
  },
});
