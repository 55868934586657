import Flex from "../../shared/styles/Flex"
import SignUpForm from "../components/forms/SignUp"

export default function SignUp() {
	return (
		<Flex css={{ height: "100vh", alignItems: "center", justifyContent: "center" }}>
			<SignUpForm />
		</Flex>
	)
}
