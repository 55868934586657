import styled from "./theme";

const StyledSubmit = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '$3',

  'a': {
    fontSize: '$2',
    color: '$grey',
    transition: 'all .3s',

    '&:hover': {
      color: 'white'
    }
  }
});

export default StyledSubmit