import styled from "../../shared/styles/theme";

const StyledMessages = styled("div", {
  display: "flex",
  maxHeight: "100vh",
  padding: "$1 $1 0 $1",
  flexDirection: "column",
  background: "rgba(0,0,0,0.2)",
});

export default StyledMessages;
