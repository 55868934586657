import { forwardRef } from "react"
import StyledSelect from "../styles/Select"

// TODO ref type

export default forwardRef((props: any, ref: any) => {
    return (
        <StyledSelect ref={ref} {...props}>
            {props.options?.length && props.options.map((option: any, index: number) => <option key={`option-${props.name}-${option.value}-${index}`}>{option.value}</option>)}
        </StyledSelect>
    )
})
