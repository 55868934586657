import Flex from "../../shared/styles/Flex"
import ResetPasswordForm from "../components/forms/ResetPassword"

export default function ResetPassword() {
  return (
    <Flex css={{ height: "100vh", alignItems: "center", justifyContent: "center" }}>
      <ResetPasswordForm />
    </Flex>
  )
}
