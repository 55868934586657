import Flex from "../../shared/styles/Flex"
import ForgotPasswordForm from "../components/forms/ForgotPassword"

export default function ForgotPassword() {
  return (
    <Flex css={{ height: "100vh", alignItems: "center", justifyContent: "center" }}>
      <ForgotPasswordForm />
    </Flex>
  )
}
