import { Auth } from "aws-amplify"
import { useEffect, useReducer, useState } from "react"
import { AuthContext, initialAuthState } from "src/auth/contexts/auth"
import { AuthReducer } from "src/auth/reducers/auth"
import { AuthActionTypes } from "src/auth/auth"
import useUsers from "src/users/hooks/useUsers"

export default function withAuth(WrappedComponent: any) {
	return (props: any) => {
		const [authChecked, setAuthChecked] = useState(false)
		const [state, dispatch] = useReducer(AuthReducer, initialAuthState)
		const { getUser } = useUsers()

		useEffect(() => {
			async function getCurrentUser() {
				try {
					const { username }: any = await Auth.currentAuthenticatedUser()

					if (username) {
						const user = await getUser(username)

						if (user) {
							dispatch({ type: AuthActionTypes.ReceiveCurrentUser, payload: { currentUser: user } })
						}
					}

					setAuthChecked(true)
				} catch (e) {
					console.log("[withAuth]", e)
					setAuthChecked(true)
				}
			}

			getCurrentUser()
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		if (!authChecked) {
			return null
		}

		return (
			<AuthContext.Provider value={{ state, dispatch }}>
				<WrappedComponent {...props} />
			</AuthContext.Provider>
		)
	}
}
