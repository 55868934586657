import { useEffect, useState } from "react"
import { useUserMedia } from "src/media/hooks/useUserMedia"
import ScreenIcon from "src/shared/icons/Screen"
import Button from "src/shared/styles/Button"
import { useKeys } from "../../shared/hooks/useKeys"

interface ScreenProps { }

export default function Screen(props: ScreenProps) {
	const { localScreenStream, getDisplayMedia } = useUserMedia()
	const [screenActive, setScreenActive] = useState<boolean>(false)
	const { keysDown, isPressed } = useKeys()

	useEffect(() => {
		if (isPressed(['AltLeft', 'KeyS'])) {
			toggleScreen()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keysDown])

	useEffect(() => {
		if (screenActive) {
			getDisplayMedia()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [screenActive])

	async function toggleScreen() {
		setScreenActive(!screenActive)
	}

	return (
		<Button onClick={toggleScreen} css={{ padding: 12, margin: 5, opacity: localScreenStream ? 1 : 0.5 }}>
			<ScreenIcon />
		</Button>
	)
}
