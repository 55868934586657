import { useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

import ErrorMessage from "src/shared/components/ErrorMessage";
import Field from "src/shared/components/Field";
import Form from "src/shared/components/Form";
import useForm from "src/shared/hooks/useForm";
import Button from "src/shared/styles/Button";
import StyledSubmit from "src/shared/styles/Submit";
import { ISignIn } from "src/auth/auth";
import useAuth from "src/auth/hooks/useAuth";
import { delay } from "src/shared/utils/delay";
import useQuery from "src/shared/hooks/useQuery";

export default function SignInForm() {
	const query = useQuery();
	const history = useHistory();
	const { handleSubmit } = useForm();
	const inputRef = useRef<HTMLInputElement>(null);
	const { signinError, signedIn, signingIn, signIn, getCurrentUser } =
		useAuth();

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		async function navigateToHome() {
			// Wait for form to animate out
			await delay(300);

			// Fetch current user, will redirect
			await getCurrentUser();
		}

		if (signedIn) {
			navigateToHome();
		}
	}, [history, signedIn, getCurrentUser]);

	/**
	 * On submit, sign in
	 *
	 * @param data
	 */
	const onSubmit = async (data: ISignIn) => {
		await signIn(data);
	};

	return (
		<Form
			loading={signingIn}
			success={signedIn}
			handleSubmit={handleSubmit(onSubmit)}
		>
			<ErrorMessage visible={Boolean(signinError)}>
				<span style={{ flexGrow: 1 }}>{signinError}</span>
			</ErrorMessage>

			<Field ref={inputRef} label="Email" name="email" type="email" required />
			<Field
				label="Password"
				name="password"
				type="password"
				labelAction={<Link to="/forgot-password">Forgot?</Link>}
				required
			/>

			<StyledSubmit>
				<Link
					to={query.get("to") ? `/signup/?to=${query.get("to")}` : "/signup"}
				>
					No account?
				</Link>
				<Button border="gradient" type="submit" disabled={signingIn}>
					Sign in
				</Button>
			</StyledSubmit>
		</Form>
	);
}
