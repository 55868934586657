import { useEffect, useRef, useState } from "react";
import Speaker from "../../shared/icons/Speaker";
import * as S from '../../shared/styles/TestSpeaker'

// TODO
export default function TestSpeakers() {
	const [isPlaying, setIsPlaying] = useState<boolean>(false)
	const audio = useRef<HTMLAudioElement>(new Audio('/assets/audio/testSpeakers.mp3'))

	useEffect(() => {
		if (isPlaying) {
			audio.current.play();
		} else {
			audio.current.pause();
			audio.current.currentTime = 0;
		}
	}, [isPlaying])

	async function onClick() {
		setIsPlaying(!isPlaying)
	}

	return (
		<S.Container onClick={onClick}>
			<S.Inner>
				<Speaker />
				<span>Test</span>
			</S.Inner>
		</S.Container>
	);
}
