import styled from "./theme";

const ErrorMessage = styled('div', {
  opacity: 0,
  margin: '2px',
  padding: '8px',
  borderRadius: '6px',
  fontSize: '$3',
  fontFamily: '$sofia',
  border: '0',
  display: 'flex',
  lineHeight: '1.5',
  alignItems: 'center',
  position: 'relative',
  background: '#0D0D0D',
  color: '$white',
  fontWeight: '500',
  transform: 'translateY(-20px) translateZ(0px) scale(0.9)',
  transformStyle: 'preserve-3d',
  marginBottom: '$2',
  textAlign: 'center',
  transition: 'all .3s',

  '&::before': {
    content: `''`,
    display: 'block',
    backgroundImage: 'linear-gradient(to top right, #FF006B, #FF1F00)',
    position: 'absolute',
    top: '-2px',
    left: '-2px',
    width: 'calc(100% + 4px)',
    height: 'calc(100% + 4px)',
    borderRadius: '8px',
    zIndex: -1,
    transform: 'translateZ(-1px)',
    transition: 'all .3s',
  },

  variants: {
    visible: {
      true: {
        opacity: 1,
        transform: 'translateY(0) translateZ(0) scale(1)',
      }
    }
  }
});

export default ErrorMessage