import styled from "../../shared/styles/theme";

export const Inner = styled("div", {
  maxWidth: "18vw",
  cursor: "pointer",
  position: "relative",
});

export const User = styled("div", {
  color: "$grey",
  fontSize: "$1",
});

export const Text = styled("div", {
  padding: "$1",
  borderRadius: "6px",
  fontSize: "$3",
  lineHeight: "1.3",
  position: "relative",
  background: "#0D0D0D",
  color: "#FFFFFF",
  fontWeight: "500",
  fontFamily: "$sofia",
  display: "inline-block",
});

export const TextInner = styled("div", {
  a: {
    textDecoration: "underline",
  },
});

export const CreatedAt = styled("div", {
  height: 0,
  opacity: 0,
  fontSize: "$1",
  color: "$grey",
  transition: "all .3s ease",
});

export const Container = styled("div", {
  margin: "0 0 $1 0",

  variants: {
    currentUser: {
      true: {
        display: "flex",
        justifyContent: "flex-end",

        [`& ${User}`]: {
          display: "none",
        },

        [`& ${CreatedAt}`]: {
          textAlign: "right",
        },
      },
    },
    detailsVisible: {
      true: {
        [`& ${CreatedAt}`]: {
          height: "1em",
          opacity: 1,
        },
      },
    },
  },
});
