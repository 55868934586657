import { forwardRef, AllHTMLAttributes } from "react";
import * as StyledField from "../styles/Field";
import StyledLabel, { Required } from "../styles/Label";
import Checkbox from "./Checkbox";
import Dropzone from "./Dropzone";
import Input from "./Input";
import Select from "./Select";
import Textarea from "./Textarea";

interface IField extends AllHTMLAttributes<HTMLInputElement> {
	type:
	| "checkbox"
	| "email"
	| "password"
	| "text"
	| "image"
	| "select"
	| "textarea";
	label?: string;
	labelAction?: JSX.Element;
	text?: JSX.Element;
	help?: JSX.Element;
	options?: { value: string; label: string }[];
}

const formElement: { [key: string]: any } = {
	text: Input,
	email: Input,
	password: Input,
	checkbox: Checkbox,
	image: Dropzone,
	select: Select,
	textarea: Textarea,
};

// TODO ref type
export default forwardRef((props: IField, ref: any) => {
	const { type, label, text, labelAction, help }: IField = props;

	if (!(type in formElement)) {
		throw new Error("Input type not supported yet");
	}

	// TODO pretty gross, but currently required
	const FormElement = forwardRef((props: any, ref) => {
		const ForwardedRefFormElement = formElement[type];
		return <ForwardedRefFormElement {...props} ref={ref} />;
	});

	const isCheckbox = type === "checkbox";

	return (
		<StyledField.Container>
			<StyledField.Field type={type}>
				<StyledField.Label>
					{(label || text) && (
						<StyledLabel>
							{isCheckbox ? text : label}
							{props.required && <Required>*</Required>}
						</StyledLabel>
					)}

					{labelAction && <StyledField.LabelAction>{labelAction}</StyledField.LabelAction>}
				</StyledField.Label>

				<FormElement ref={ref} {...props} />
			</StyledField.Field>

			{help && (
				<StyledField.Help>
					{help}
				</StyledField.Help>
			)}
		</StyledField.Container>
	);
});
