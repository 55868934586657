function Settings(props: any) {
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.832 4.21L13.5 2h-3l-.332 2.21c-.854.2-1.656.538-2.38.987L5.99 3.868 3.868 5.99l1.33 1.798a7.953 7.953 0 00-.987 2.38L2 10.5v3l2.21.332c.2.854.538 1.656.987 2.38L3.868 18.01l2.122 2.122 1.798-1.33c.724.45 1.526.787 2.38.987L10.5 22h3l.332-2.21a7.955 7.955 0 002.38-.987l1.798 1.329 2.122-2.122-1.33-1.798c.45-.724.787-1.526.987-2.38L22 13.5v-3l-2.21-.332a7.955 7.955 0 00-.987-2.38l1.329-1.798-2.122-2.122-1.798 1.33a7.954 7.954 0 00-2.38-.987zM12 16a4 4 0 100-8 4 4 0 000 8z"
                fill="#fff"
            />
        </svg>
    )
}

export default Settings
