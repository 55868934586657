import Logo from "../../shared/icons/Logo"
import Flex from "../../shared/styles/Flex"
import ReserveUsernameForm from "../components/forms/ReserveUsername"

export default function ReserveUsername() {
	return (
		<Flex css={{ height: "100vh", alignItems: "center", justifyContent: "center" }}>
			<Flex css={{ position: "absolute", marginBottom: "-80px", alignItems: "center", justifyContent: "center" }}>
				<ReserveUsernameForm />
			</Flex>
			<Flex css={{ marginTop: "-160px" }}>
				<Logo />
			</Flex>
		</Flex>
	)
}
