import styled from "./theme";

const StyledLabel = styled('label', {
  padding: '$1',
  textTransform: 'uppercase',
  fontSize: '10px',
  display: 'inline-block',
  color: '$greyLight',
  letterSpacing: '0.2em'
});

export const Required = styled('span', {
  color: 'red',
  fontSize: '$1',
  lineHeight: 1,
});

export default StyledLabel