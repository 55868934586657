import { keyframes } from "@stitches/react";
import styled from "./theme";

const scaleUp = keyframes({
  '0%': { opacity: 0, transform: 'translate3d(-20px,-30px,0) scale(0)' },
  '100%': { opacity: 1, transform: 'translate3d(0,0,0) scale(1)' },
});

export const Container = styled('div', {
  margin: '0 $1 $1 $1',
  padding: '$2',
  color: '$grey',
  fontSize: '$1',
  cursor: 'pointer',
  borderRadius: 4,
  textAlign: 'center',
  border: '2px dashed rgba(255,255,255,0.1)',
  transition: 'all .3s',

  '&:hover': {
    borderColor: 'rgba(255,255,255,0.3)',
  },

  '&:focus': {
    borderColor: 'rgba(255,255,255,0.3)',
  },

  '&:active': {
    borderColor: 'rgba(255,255,255,0.4)',
    boxShadow: '0 0 10px 0 rgba(255,255,255,0.1)'
  },
});

export const ThumbnailContainer = styled('div', {
  margin: '$1',
  display: 'grid',
  borderRadius: 6,
})

export const Thumbnail = styled('div', {

})

export const ThumbnailImage = styled('img', {
  width: 50,
  height: 50,
  borderRadius: 6,
  display: 'block',
  objectFit: 'cover',
  background: '$greyDarker',

  opacity: 0,
  transform: 'translate3d(-20px,-30px,0) scale(0)',
  transformOrigin: '80%, 80%',
  animation: `${scaleUp} .3s`,
  animationFillMode: 'forwards'
})

export default Container