import styled from "./theme";

const StyledForm = styled('form', {
  width: '300px',
  transition: 'all 0.2s',
  position: 'relative',

  variants: {
    loading: {
      true: {
        pointerEvents: 'none',
        transform: 'scale(0.95)',
        filter: 'grayscale(1) brightness(0.5) opacity(0.4)'
      }
    },
    success: {
      true: {
        pointerEvents: 'none',
        transform: 'scale(0.6)',
        filter: 'grayscale(1) brightness(0.5) opacity(0)'
      }
    }
  }
});

export default StyledForm