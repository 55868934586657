import { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"

import * as StyledDropzone from "../styles/Dropzone"

interface IDropzone {
	name: string
}

export default function Dropzone({ name }: IDropzone) {
	const [files, setFiles] = useState<any>([])

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		onDrop: (acceptedFiles: any) => {
			setFiles(
				acceptedFiles.map((file: any) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file)
					})
				)
			)
		}
	})

	const thumbnails = files.map((file: any) => (
		<StyledDropzone.ThumbnailContainer key={file.name}>
			<StyledDropzone.Thumbnail>
				<StyledDropzone.ThumbnailImage src={file.preview} />
			</StyledDropzone.Thumbnail>
		</StyledDropzone.ThumbnailContainer>
	))

	useEffect(
		() => () => {
			files.forEach((file: any) => URL.revokeObjectURL(file.preview))
		},
		[files]
	)

	return (
		<>
			<StyledDropzone.Container>
				<div {...getRootProps()}>
					<input {...getInputProps({ name })} />
					<span>Drag your image here or click to browse files.</span>
				</div>
			</StyledDropzone.Container>
			{thumbnails}
		</>
	)
}
