import { AuthActionTypes, AuthStateType, AuthActions } from "src/auth/auth";

export const AuthReducer = (
  prevState: AuthStateType,
  action: AuthActions
): AuthStateType => {
  switch (action.type) {
    case AuthActionTypes.SignedIn:
      return {
        ...prevState,
        signedIn: action.payload.signedIn,
      };
    case AuthActionTypes.SigningIn:
      return {
        ...prevState,
        signingIn: action.payload.signingIn,
      };
    case AuthActionTypes.SigninError:
      return {
        ...prevState,
        signinError: action.payload.signinError,
      };

    case AuthActionTypes.SignedUp:
      return {
        ...prevState,
        signedUp: action.payload.signedUp,
      };
    case AuthActionTypes.SignupUserData:
      return {
        ...prevState,
        signupUserData: action.payload.signupUserData,
      };
    case AuthActionTypes.SigningUp:
      return {
        ...prevState,
        signingUp: action.payload.signingUp,
      };
    case AuthActionTypes.SignupError:
      return {
        ...prevState,
        signupError: action.payload.signupError,
      };

    case AuthActionTypes.SignedOut:
      return {
        ...prevState,
        signedOut: action.payload.signedOut,
        currentUser: undefined,
      };
    case AuthActionTypes.SigningOut:
      return {
        ...prevState,
        signingOut: action.payload.signingOut,
      };
    case AuthActionTypes.SignoutError:
      return {
        ...prevState,
        signoutError: action.payload.signoutError,
      };

    case AuthActionTypes.Verified:
      return {
        ...prevState,
        verified: action.payload.verified,
      };
    case AuthActionTypes.Verifying:
      return {
        ...prevState,
        verifying: action.payload.verifying,
      };
    case AuthActionTypes.VerifyError:
      return {
        ...prevState,
        verifyError: action.payload.verifyError,
      };

    case AuthActionTypes.SendingPasswordResetEmail:
      return {
        ...prevState,
        sendingPasswordResetEmail: action.payload.sendingPasswordResetEmail,
      };
    case AuthActionTypes.ResetPasswordEmailSent:
      return {
        ...prevState,
        resetPasswordEmailSent: action.payload.resetPasswordEmailSent,
      };
    case AuthActionTypes.ResetPasswordEmailError:
      return {
        ...prevState,
        resetPasswordEmailError: action.payload.resetPasswordEmailError,
      };

    case AuthActionTypes.ResettingPassword:
      return {
        ...prevState,
        resettingPassword: action.payload.resettingPassword,
      };
    case AuthActionTypes.PasswordReset:
      return {
        ...prevState,
        passwordReset: action.payload.passwordReset,
      };
    case AuthActionTypes.ResetPasswordError:
      return {
        ...prevState,
        resetPasswordError: action.payload.resetPasswordError,
      };

    case AuthActionTypes.ReceiveCurrentUser:
      return {
        ...prevState,
        currentUser: action.payload.currentUser,
      };
  }
};
