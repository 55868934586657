import styled from "../../shared/styles/theme";

export const List = styled("div", {
  flex: 1,
  overflow: "auto",
});

export const DateSeperator = styled("div", {
  fontSize: "$1",
  color: "$greyLight",
  margin: "20px 0",
  textAlign: "center",
  letterSpacing: "1px",
  textTransform: "uppercase",
});

export default List;
