import styled from "./theme";

const StyledCallActions = styled('div', {
  width: '100%',
  padding: '$2',
  display: 'flex',
  alignItems: 'center',

  '& > div': {
    flex: 1,
    display: 'flex',

    '&:nth-child(2)': {
      justifyContent: 'center',
    }
  }
});

export default StyledCallActions