import Amplify, { Auth } from "aws-amplify"
import awsconfig from "./aws-exports"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import globalStyles from "./shared/styles/global"

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

globalStyles()

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
