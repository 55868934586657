function Microphone(props: any) {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect x={9} y={2} width={6} height={12} rx={3} fill="#fff" />
			<path fill="#fff" d="M11 16h2v5h-2z" />
			<path fill="#fff" d="M8 20h8v2H8z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5 9v2a7 7 0 1014 0V6v-.023V9h-2v2a5 5 0 01-10 0V9H5z"
				fill="#fff"
			/>
		</svg>
	)
}

export default Microphone
