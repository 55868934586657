import { ActionMap } from "src/shared/shared";
import { IUser } from "src/users/user";

export type AuthStateType =
  | {
      signingIn?: boolean;
      signedIn?: boolean;
      signinError?: string;

      signingUp?: boolean;
      signupUserData?: ISignUp;
      signedUp?: boolean;
      signupError?: string;

      signingOut?: boolean;
      signedOut?: boolean;
      signoutError?: string;

      verifying?: boolean;
      verified?: boolean;
      verifyError?: string;

      sendingPasswordResetEmail?: boolean;
      resetPasswordEmailSent?: boolean;
      resetPasswordEmailError?: string;

      resettingPassword?: boolean;
      passwordReset?: boolean;
      resetPasswordError?: string;

      currentUser?: any;
    }
  | undefined;

export interface ISignIn {
  email: string;
  password: string;
}

export interface ISignUp {
  id?: string;
  email: string;
  password: string;
  fullName?: string;
  profilePicture?: string;
}

export interface IVerify {
  code: string;
  signupUserData: ISignUp;
}

export interface IForgot {
  email: string;
}

export interface IResetPassword {
  code: string;
  password: string;
  confirmPassword: string;
}

export enum AuthActionTypes {
  SigningIn = "SIGNING_IN",
  SignedIn = "SIGNED_IN",
  SigninError = "SIGNIN_ERROR",

  SigningUp = "SIGNING_UP",
  SignupUserData = "SIGNUP_USER_DATA",
  SignedUp = "SIGNED_UP",
  SignupError = "SIGNUP_ERROR",

  SigningOut = "SIGNING_OUT",
  SignedOut = "SIGNED_OUT",
  SignoutError = "SIGNOUT_ERROR",

  Verifying = "VERIFYING",
  Verified = "VERIFIED",
  VerifyError = "VERIFIY_ERROR",

  SendingPasswordResetEmail = "SENDING_PASSWORD_RESET_EMAIL",
  ResetPasswordEmailSent = "RESET_PASSWORD_EMAIL_SENT",
  ResetPasswordEmailError = "RESET_PASSWORD_EMAIL_ERROR",

  ResettingPassword = "RESETTING_PASSWORD",
  PasswordReset = "PASSWORD_RESET",
  ResetPasswordError = "RESET_PASSWORD_ERROR",

  ReceiveCurrentUser = "RECEIVE_CURRENT_USER",
}

type AuthPayload = {
  [AuthActionTypes.SigningIn]: {
    signingIn: boolean;
  };
  [AuthActionTypes.SignedIn]: {
    signedIn: boolean;
  };
  [AuthActionTypes.SigninError]: {
    signinError: string;
  };

  [AuthActionTypes.SigningUp]: {
    signingUp: boolean;
  };
  [AuthActionTypes.SignupUserData]: {
    signupUserData: ISignUp;
  };
  [AuthActionTypes.SignedUp]: {
    signedUp: boolean;
  };
  [AuthActionTypes.SignupError]: {
    signupError: string;
  };

  [AuthActionTypes.SigningOut]: {
    signingOut: boolean;
  };
  [AuthActionTypes.SignedOut]: {
    signedOut: boolean;
  };
  [AuthActionTypes.SignoutError]: {
    signoutError: string;
  };

  [AuthActionTypes.Verifying]: {
    verifying: boolean;
  };
  [AuthActionTypes.Verified]: {
    verified: boolean;
  };
  [AuthActionTypes.VerifyError]: {
    verifyError: string;
  };

  [AuthActionTypes.SendingPasswordResetEmail]: {
    sendingPasswordResetEmail: boolean;
  };
  [AuthActionTypes.ResetPasswordEmailSent]: {
    resetPasswordEmailSent: boolean;
  };
  [AuthActionTypes.ResetPasswordEmailError]: {
    resetPasswordEmailError: string;
  };

  [AuthActionTypes.ResettingPassword]: {
    resettingPassword: boolean;
  };
  [AuthActionTypes.PasswordReset]: {
    passwordReset: boolean;
  };
  [AuthActionTypes.ResetPasswordError]: {
    resetPasswordError: string;
  };

  [AuthActionTypes.ReceiveCurrentUser]: {
    currentUser?: Partial<IUser> | null;
  };
};

export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];
