import styled from "./theme";

const StyledSelect = styled("select", {
  padding: "$1",
  paddingRight: "$4",
  borderRadius: "6px",
  fontSize: "$3",
  lineHeight: "1",
  border: "0",
  position: "relative",
  appearance: "none",
  background: "#0D0D0D",
  color: "#FFFFFF",
  fontWeight: "500",
  fontFamily: "$sofia",
  display: "block",
  width: "100%",
  textOverflow: "ellipsis",
  transition: "all .2s",

  "&:hover": {
    boxShadow: "0 0 0 2px rgba(255,255,255,0.1)",
  },

  "&:focus": {
    outline: 0,
    boxShadow:
      "0 0 0 2px rgba(255,255,255,1), 0 0 10px 0 rgba(255,255,255,0.5)",
  },
});

export default StyledSelect;
