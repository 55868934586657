import { createStitches } from "@stitches/react";

const { styled } = createStitches({
  theme: {
    colors: {
      white: "#FFFFFF",
      greyLight: "#C4C4C4",
      grey: "#747474",
      greyDark: "#1E1E1E",
      greyDarker: "#0D0D0D",
      red: "#EE4747",
    },
    space: {
      1: "10px",
      2: "16px",
      3: "24px",
      4: "32px",
      5: "48px",
      6: "64px",
      7: "80px",
    },
    fontSizes: {
      1: "12px",
      2: "14px",
      3: "16px",
      4: "26px",
      5: "42px",
      6: "68px",
    },
    fonts: {
      sofia: "Sofia Pro",
    },
    fontWeights: {},
    lineHeights: {},
    letterSpacings: {},
    sizes: {},
    borderWidths: {},
    borderStyles: {},
    radii: {},
    shadows: {},
    zIndices: {},
    transitions: {},
  },
});

export default styled;
